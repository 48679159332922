import { createSelector } from "reselect";
import { get, isNil } from "lodash";

import {
  transferRecipientSelector,
  payoutTypeSelector,
  destinationCountrySelector,
  transferOriginCountrySelector,
  fundingSourceSelector,
} from "../transfer/transfer.selectors";
import CountryService from "../../services/country-service/country.service";
import { mapDropdownOptions } from "../../services/helpers.service";
import { userDataSelector, userCountrySelector } from "../user/user.selectors";
import documentService from "../../services/document.service";
import { IAppState } from "../../types";
import environment from "../../../../environment";

import { OrganizationStoreConstants } from "./organization.constants";
import { IRegistrationField, OTPSource } from "./organization.types";

const {
  MOBILE_CONFIGURATION,
  REGISTRATION_FIELDS,
  ORGANIZATION_DATA,
  EXTRA_OPTIONS,
  ORGANIZATION_AGENTS,
  SOURCE_CURRENCIES,
  DESTINATION_CURRENCIES,
  RECIPIENT_RELATIONSHIPS,
  SOURCES_OF_FUNDS,
  PURPOSES_OF_TRANSFER,
  PAYMENT_PROCESSORS_BY_COUNTRY,
  ID_DOC_TYPES,
  DOC_TYPES,
  REQUIRED_DOCS,
} = OrganizationStoreConstants;

export const registrationFieldsSelector = ({ organization }: IAppState) =>
  get(organization, REGISTRATION_FIELDS) as IRegistrationField[];

export const mobileConfigurationSelector = ({ organization }: IAppState) =>
  get(organization, MOBILE_CONFIGURATION);

export const organizationSettingsSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "settings", {}),
);

export const calculatorDefaultValuesSelector = createSelector(
  mobileConfigurationSelector,
  mobConfig => get(mobConfig, ["settings", "calculatorDefaultValues"], {}),
);

export const referralCampaignDataSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "referralCampaignData", {}),
);

export const payoutTypesComponentsConfigSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "payoutComponentsConfiguration", {}),
);

export const footerTextSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "settings.footerText", ""),
);

export const organizationCopyrightTextSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "settings.organizationCopyrightText", ""),
);

export const transferDataByCountrySelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "transferDataByCountry", []),
);

export const agentCountrySelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "agentCountry", ""),
);

export const sourceCurrenciesSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, SOURCE_CURRENCIES, []),
);

export const destinationCurrenciesSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, DESTINATION_CURRENCIES, []),
);

export const agentIdSelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => {
    return get(mobileConfig, "agentIdHC", environment.agentId);
  },
);

export const requiredDocumentsSelector = createSelector(
  organizationSettingsSelector,
  orgSettings => get(orgSettings, REQUIRED_DOCS, []),
);

export const payTypesByCountrySelector = createSelector(
  mobileConfigurationSelector,
  mobileConfig => get(mobileConfig, "payinTypesByCountry", []),
);

export const isCardSaveFeatureSupportedSelector = createSelector(
  payTypesByCountrySelector,
  userDataSelector,
  (payinTypesByCountry, userData) => {
    const userCountry = get(userData, "country");
    const featureSupportedByCountry = payinTypesByCountry.reduce(
      (acc: any, curr: any) => {
        acc[curr.country] = curr.cardSaveSupported;
        return acc;
      },
      {},
    );

    return featureSupportedByCountry[userCountry];
  },
);

export const organizationDataSelector = ({ organization }: IAppState) =>
  get(organization, ORGANIZATION_DATA);

export const organizationAgentsSelector = ({ organization }: IAppState) =>
  get(organization, ORGANIZATION_AGENTS);

export const docTypesSelector = ({ organization }: IAppState) =>
  get(organization, [EXTRA_OPTIONS, DOC_TYPES]);

export const organizationNameSelector = createSelector(
  organizationDataSelector,
  orgData => get(orgData, "organizationName1", "UnitySend"), // expansion
);

export const organizationIdSelector = createSelector(
  organizationDataSelector,
  orgData => get(orgData, "organizationId"),
);

export const sourceCountriesSelector = createSelector(
  mobileConfigurationSelector,
  orgData => get(orgData, "sourceCountries1", ["US"]), // expansion
);

export const originCountriesSelector = createSelector(
  organizationDataSelector,
  orgData => get(orgData, "originCountries", []),
);

export const destinationCountriesSelector = createSelector(
  organizationDataSelector,
  orgData => get(orgData, "destinationCountries", []),
);

export const fundingSourcesSelector = createSelector(
  payTypesByCountrySelector,
  userDataSelector,
  (payinTypesByCountry, userData) => {
    const userCountry = get(userData, "country");

    return get(
      payinTypesByCountry.find(
        ({ country }: { country: string }) => country === userCountry,
      ),
      "fundingSources",
      [],
    );
  },
);

export const recipientRelationshipsOptionsSelector = ({
  organization,
}: IAppState) =>
  mapDropdownOptions(
    get(organization, [EXTRA_OPTIONS, RECIPIENT_RELATIONSHIPS], []),
  );

export const purposesOfTransferOptionsSelector = ({
  organization,
}: IAppState) =>
  mapDropdownOptions(
    get(organization, [EXTRA_OPTIONS, PURPOSES_OF_TRANSFER], []),
  );

export const sourcesOfFundsOptionsSelector = ({ organization }: IAppState) =>
  mapDropdownOptions(get(organization, [EXTRA_OPTIONS, SOURCES_OF_FUNDS], []));

export const sourceCurrenciesOptionsSelector = createSelector(
  sourceCurrenciesSelector,
  state => payoutTypeSelector(state),
  (sourceCurrencies, payoutType) =>
    payoutType ? mapDropdownOptions(sourceCurrencies) : [],
);

export const destinationCurrenciesOptionsSelector = createSelector(
  destinationCurrenciesSelector,
  state => payoutTypeSelector(state),
  (destinationCurrencies, payoutType) =>
    payoutType ? mapDropdownOptions(destinationCurrencies) : [],
);

export const originCountriesOptionsSelector = createSelector(
  originCountriesSelector,
  countries => mapDropdownOptions(countries, CountryService.getFullName),
);

export const sourceCountriesOptionsSelector = createSelector(
  sourceCountriesSelector,
  countries => {
    return mapDropdownOptions(countries, CountryService.getFullName);
  },
);

export const destinationCountriesOptionsSelector = createSelector(
  transferDataByCountrySelector,
  state => transferRecipientSelector(state),
  (countries, recipient) =>
    mapDropdownOptions(Object.keys(countries), CountryService.getFullName),
);

export const allCountriesOptionsSelector = () =>
  mapDropdownOptions(
    CountryService.getAllCountries(),
    CountryService.getFullName,
  );

export const paymentProcessorsByCountrySelector = createSelector(
  mobileConfigurationSelector,
  config => get(config, PAYMENT_PROCESSORS_BY_COUNTRY),
);

export const idDocTypesSelector = ({ organization, user }: IAppState) => {
  const idDocTypes: string[] = get(
    organization,
    [EXTRA_OPTIONS, ID_DOC_TYPES],
    [],
  );
  const country = get(user.userData, "country");
  if (country !== "GB") {
    return idDocTypes.filter(el => el !== "UK_ID");
  }
  return idDocTypes;
};

export const idDocTypesOptionsSelector = createSelector(
  idDocTypesSelector,
  types => mapDropdownOptions(types, documentService.getDocumentLabel),
);

export const paymentProcessorSelector = createSelector(
  state => fundingSourceSelector(state),
  payTypesByCountrySelector,
  userDataSelector,
  (fundingSource, payinTypesByCountry, userData) => {
    const userCountry = get(userData, "country");

    const payinTypes = get(
      payinTypesByCountry.find(
        ({ country }: { country: string }) => country === userCountry,
      ),
      "mobileFundingSources",
      [],
    );
    const paymentProcessor = get(
      payinTypes.find(
        ({ payinType }: { payinType: string }) => payinType === fundingSource,
      ),
      "fundingSourceProcessor",
    );
    return paymentProcessor;
  },
);

export const pickupLocationsByCountrySelector = createSelector(
  mobileConfigurationSelector,
  config => get(config, "settings.cashPickupLocations"),
);

export const sourceCountrySelector = createSelector(
  (state: IAppState) => transferOriginCountrySelector(state),
  userCountrySelector,
  (transferOrigin, userCountry) => transferOrigin || userCountry,
);

export const destinationCountryPickupLocationsSelector = createSelector(
  (state: IAppState) => pickupLocationsByCountrySelector(state),
  (state: IAppState) => destinationCountrySelector(state),
  (locations, destCountry) => get(locations, `${destCountry}`, []),
);

export const sourceCountriesPhonePrefixesOptionsSelector = createSelector(
  sourceCountriesOptionsSelector,
  sourceCountries =>
    sourceCountries.map(({ value }) => ({
      label: CountryService.getPhonePrefix(value),
      value,
    })),
);

export const defaultSourceCountrySelector = createSelector(
  (state: IAppState) => transferOriginCountrySelector(state),
  calculatorDefaultValuesSelector,
  (selectedSourceTransferCountry, defaultValues) =>
    selectedSourceTransferCountry || defaultValues.sourceCountry,
);

export const mobileAppsLinksSelector = createSelector(
  organizationSettingsSelector,
  orgSettings => get(orgSettings, "mobileAppLinks", {}),
);

export const recipientPhonePrefixesOptionsSelector = createSelector(
  (state: IAppState) => destinationCountriesOptionsSelector(state),
  (state: IAppState) => destinationCountrySelector(state),
  (destCountries, transferDestCountry) =>
    destCountries
      .filter(({ value }) =>
        transferDestCountry ? value === transferDestCountry : true,
      )
      .map(({ value }) => ({
        label: CountryService.getPhonePrefix(value),
        value,
      })),
);

export const selectOtpSourceType = createSelector(
  mobileConfigurationSelector,
  config => get(config, "transactionTwoFaType", OTPSource.LIFETIME_OTP_CODE),
);

export const selectOtpSecondsToResend = createSelector(
  mobileConfigurationSelector,
  config => get(config, "settings.transactionOtpTimes.secondsToResend", 60),
);

export const selectUserUploadDocumentTypes = createSelector(
  organizationDataSelector,
  data => get(data, OrganizationStoreConstants.USER_DOCS_TO_UPLOAD, []),
);

export const selectUserUploadDocumentTypesAsSelector = ({
  organization,
}: IAppState) => {
  const fields = get(
    organization,
    OrganizationStoreConstants.USER_DOCS_TO_UPLOAD,
    [],
  );
  return !isNil(fields)
    ? fields.map(({ idType, idTypeTitle }: any) => ({
        label: idTypeTitle,
        value: idType,
      }))
    : [];
};
