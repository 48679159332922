import { get } from "lodash";

import { createReducer } from "../../utils";
import { SUCCESS, FAILURE } from "../../utils/types/create-constants.types";

import {
  getPriceTypes,
  SET_TRANSFER_CURRENCY,
  SET_TRANSFER_DESTINATION_COUNTRY,
  SET_TRANSFER_PAYOUT_TYPE,
  RESET_PRICE_RESPONSE,
  TransferDetailsConstants,
  SET_TRANSFER_RECIPIENT,
  SET_SOURCE_OF_FUNDS,
  SET_PURPOSE_OF_TRANSFER,
  getBanksByCountryTypes,
  getMobileOperatorsByCountryTypes,
  getMobileOperatorByPhoneNumber,
  getBankBranchesTypes,
  getPickupLocationsTypes,
  SET_PICKUP_LOCATION,
  SET_FUNDING_SOURCE,
  SET_MOBILE_OPERATOR,
  createNewTransactionTypes,
  getBankAccountDetailsTypes,
  SET_CREDIT_CARD_DETAILS,
  SET_SECURE_3D_INFO,
  getTransactionByQueryCodeTypes,
  SET_CASH_CODE,
  APPLY_COMPLIANCE_RULE,
  CLEAR_COMPLIANCE_RULE,
  verifyPromocodeTypes,
  SET_REVERSE_CALCULATION,
  SET_TRANSFER_SOURCE_AMOUNT,
  SET_TRANSFER_DESTINATION_AMOUNT,
  getCellphoneProductsTypes,
  SET_TRANSFER_PRICE,
  RESET_TRANSFER_DETAILS,
  getProcessorBanksTypes,
  SET_BIC,
  SET_QUERY_BY_CODE_PAYLOAD,
  SET_TRANSFER_SOURCE_COUNTRY,
  SET_RECEIPT_DATA,
  SET_TRANSFER_RECIPIENT_PHONE,
  SET_TRANSFER_RECIPIENT_BANK_ACCOUNT,
  SET_AIRTIME_PRODUCT,
  SET_TRANSFER_CARD_NUMBER,
  getBankByIfscTypes,
  RESET_IFSC_DETAILS,
  getCitiesByCountryTypes,
  getBranchesByCityTypes,
  SET_RESEND_DATA,
  SET_SPLIT_TRANSACTION,
  checkComplianceTypes,
  SET_BANK_EXTERNAL_CODE,
  RECIPIENT_FLUSH_EXTRA_ADDRESS,
  RECIPIENT_SET_EXTRA_ADDRESS,
  SET_TRANSACTION_START_TIME,
  SET_RECIPIENT_ACCOUNT_VERIFIED,
  OTP_FLUST_STATE,
  OTP_SET_RESPONSE_MESSAGE,
  OTP_SET_ERROR_MESSAGE,
  OTP_SET_RESPONSE_FLAG,
  otpCheckVerificationRequiredTypes,
  MARK_RECIPIENT_AS_VALID,
} from "./transfer.constants";
import { ITransferData, VerificationActionTypeEnum } from "./transfer.types";

const {
  TRANSFER_PRICE,
  TRANSFER_PRICE_ERROR,
  BANKS_BY_COUNTRY,
  BANK_BRANCHES,
  PICKUP_LOCATIONS,
  TRANSACTION_NEW_RESPONSE,
  BANK_ACCOUNT_TRANSFER_INFO,
  TRANSACTION_DATA_BY_QUERY_CODE,
  ACTIVE_COMPLIANCE_RULE,
  MOBILE_OPERATORS_BY_COUNTRY,
  MOBILE_OPERATOR_BY_PHONE_NUMBER,
  CELLPHONE_PRODUCTS,
  PROMO_CODE,
  CITIES_BY_COUNTRY,
  BRANCHES_BY_CITY,
  PROCESSOR_BANKS,
  IFSC_BANK_DETAILS,
  IS_SPLIT_TRANSACTION,
  COMPLIANCE_CHECK,
  TRANSACTION_START_TIME,
  RECIPIENT_VALIDATION,
  OTP_ERROR_TEXT,
  OTP_RESPONSE_FLAG,
  OTP_RESPONSE_TEXT,
  OTP_IS_VERIFICATION_REQUIRED,
} = TransferDetailsConstants;

export default createReducer<ITransferData>(
  {
    [SET_RECIPIENT_ACCOUNT_VERIFIED]: (state, { payload }) => ({
      ...state,
      [RECIPIENT_VALIDATION]: get(payload, "validatorResponse", null),
    }),
    [RECIPIENT_FLUSH_EXTRA_ADDRESS]: (state, { payload }) => ({
      ...state,
      recipientExtraAddress: undefined,
    }),
    [RECIPIENT_SET_EXTRA_ADDRESS]: (state, { payload }) => ({
      ...state,
      recipientExtraAddress: payload,
    }),
    [SET_TRANSFER_SOURCE_COUNTRY]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSACTION_START_TIME]: (state, { payload }) => ({
      ...state,
      [TRANSACTION_START_TIME]: payload,
    }),
    [SET_TRANSFER_DESTINATION_COUNTRY]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_PAYOUT_TYPE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_CURRENCY]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_SOURCE_AMOUNT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_DESTINATION_AMOUNT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_RECIPIENT]: (state, { payload }) => ({
      ...state,
      ...payload,
      [RECIPIENT_VALIDATION]: null,
    }),
    [SET_AIRTIME_PRODUCT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_RECIPIENT_PHONE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_RECIPIENT_BANK_ACCOUNT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_RECEIPT_DATA]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_PURPOSE_OF_TRANSFER]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_SOURCE_OF_FUNDS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_TRANSFER_CARD_NUMBER]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_PICKUP_LOCATION]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_MOBILE_OPERATOR]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_BANK_EXTERNAL_CODE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_FUNDING_SOURCE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_CREDIT_CARD_DETAILS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_SECURE_3D_INFO]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_CASH_CODE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_REVERSE_CALCULATION]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_BIC]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_QUERY_BY_CODE_PAYLOAD]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [getPriceTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [TRANSFER_PRICE]: payload,
    }),
    [SET_TRANSFER_PRICE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [createNewTransactionTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [TRANSACTION_NEW_RESPONSE]: payload,
    }),
    [checkComplianceTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [COMPLIANCE_CHECK]: payload,
    }),
    [getBanksByCountryTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [BANKS_BY_COUNTRY]: get(payload, "bankDetails"),
    }),
    [getCitiesByCountryTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [CITIES_BY_COUNTRY]: get(payload, "cities"),
    }),
    [getBranchesByCityTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [PICKUP_LOCATIONS]: get(payload, "cashPickupLocations"),
    }),
    [getMobileOperatorsByCountryTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [MOBILE_OPERATORS_BY_COUNTRY]: get(payload, "mobileOperatorDetails"),
    }),
    [getMobileOperatorByPhoneNumber[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [MOBILE_OPERATOR_BY_PHONE_NUMBER]: payload,
    }),
    [getMobileOperatorByPhoneNumber[FAILURE]]: (state, { error }) => ({
      ...state,
      [MOBILE_OPERATOR_BY_PHONE_NUMBER]: undefined,
    }),
    [getCellphoneProductsTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [CELLPHONE_PRODUCTS]: payload,
    }),
    [getCellphoneProductsTypes[FAILURE]]: (state, { error }) => ({
      ...state,
      [CELLPHONE_PRODUCTS]: { products: [] },
    }),
    [getProcessorBanksTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [PROCESSOR_BANKS]: get(payload, "bankDetails"),
    }),
    [getProcessorBanksTypes[FAILURE]]: state => ({
      ...state,
      ProccessorBanks: null,
    }),
    [getBankBranchesTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [BANK_BRANCHES]: get(payload, "bankBranchDetails"),
    }),
    [getPickupLocationsTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [PICKUP_LOCATIONS]: get(payload, "locationDetails"),
    }),
    [getPriceTypes[FAILURE]]: (state, { error }) => ({
      ...state,
      [TRANSFER_PRICE]: null,
      [TRANSFER_PRICE_ERROR]: error,
    }),
    [getBankAccountDetailsTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [BANK_ACCOUNT_TRANSFER_INFO]: get(payload, "bankAccountDetails"),
    }),
    [getTransactionByQueryCodeTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [TRANSACTION_DATA_BY_QUERY_CODE]: payload,
    }),
    [getTransactionByQueryCodeTypes[FAILURE]]: state => ({
      ...state,
      [TRANSACTION_DATA_BY_QUERY_CODE]: null,
    }),
    [RESET_PRICE_RESPONSE]: state => ({
      ...state,
      [TRANSFER_PRICE]: null,
    }),
    [APPLY_COMPLIANCE_RULE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [getBankByIfscTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [IFSC_BANK_DETAILS]: payload,
    }),
    [getBankByIfscTypes[FAILURE]]: state => ({
      ...state,
      [IFSC_BANK_DETAILS]: {},
    }),
    [RESET_IFSC_DETAILS]: state => ({
      ...state,
      [IFSC_BANK_DETAILS]: undefined,
    }),
    [CLEAR_COMPLIANCE_RULE]: state => ({
      ...state,
      [ACTIVE_COMPLIANCE_RULE]: null,
    }),
    [verifyPromocodeTypes[SUCCESS]]: (
      state,
      { payload: { valid, promoCode } },
    ) => ({
      ...state,
      [PROMO_CODE]: valid ? promoCode : undefined,
    }),
    [verifyPromocodeTypes[FAILURE]]: state => ({
      ...state,
      [PROMO_CODE]: undefined,
    }),
    [SET_RESEND_DATA]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_SPLIT_TRANSACTION]: (state, { payload }) => ({
      ...state,
      [IS_SPLIT_TRANSACTION]: payload,
    }),
    [RESET_TRANSFER_DETAILS]: () => ({}),
    [OTP_FLUST_STATE]: state => ({
      ...state,
      [OTP_ERROR_TEXT]: null,
      [OTP_RESPONSE_FLAG]: null,
      [OTP_RESPONSE_TEXT]: null,
    }),
    [OTP_SET_RESPONSE_MESSAGE]: (state, { payload }) => ({
      ...state,
      [OTP_RESPONSE_TEXT]: payload.otpResponseText,
    }),
    [OTP_SET_ERROR_MESSAGE]: (state, { payload }) => ({
      ...state,
      [OTP_ERROR_TEXT]: payload.otpErrorText,
    }),
    [OTP_SET_RESPONSE_FLAG]: (state, { payload }) => ({
      ...state,
      [OTP_RESPONSE_FLAG]: payload.otpResponseFlag,
    }),
    [otpCheckVerificationRequiredTypes[SUCCESS]]: (
      state,
      { payload: { required } },
    ) => ({
      ...state,
      [OTP_IS_VERIFICATION_REQUIRED]: required,
    }),
    [otpCheckVerificationRequiredTypes[FAILURE]]: state => ({
      ...state,
      [OTP_IS_VERIFICATION_REQUIRED]: false,
    }),
    [MARK_RECIPIENT_AS_VALID]: state => ({
      ...state,
      [RECIPIENT_VALIDATION]: {
        ...get(state, RECIPIENT_VALIDATION),
        actionType: VerificationActionTypeEnum.PATCHED,
      },
    }),
  },
  {
    [RECIPIENT_VALIDATION]: null,
    [OTP_ERROR_TEXT]: null,
    [OTP_RESPONSE_FLAG]: null,
    [OTP_RESPONSE_TEXT]: null,
    [OTP_IS_VERIFICATION_REQUIRED]: null,
  },
);
