import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  delay,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import { difference, get, isNil, sortBy } from "lodash-es";
import Axios from "axios";

import toast from "../../../utils/toast";
import environment from "../../../../../environment";
import { requestSagaHandler } from "../../../utils/sagas";
import { Modals, Spinners } from "../../ui/ui.constants";
import {
  isUserHasRequiredComplianceDocumentsSelector,
  userDataSelector,
  userDocTypesSelector,
  userIdSelector,
} from "../../user/user.selectors";
import { moneyTransferRoutes } from "../../../constants";
import { FAILURE, SUCCESS } from "../../../utils/types/create-constants.types";
import { api } from "../../../utils/apiClient";
import {
  setDocumentUploadRedirectRoute,
  toggleModal,
  toggleSpinner,
} from "../../ui/ui.actions";
import {
  agentIdSelector,
  paymentProcessorSelector,
} from "../../organization/organization.selectors";
import countdown from "../../../utils/countdown";
import {
  COMPLETE_TRANSACTION_WITHOUT_DOCS,
  COMPLIANCE_RULE_FAILED,
  ComplianceRules,
  ComplianceRulesPriority,
  CONFIRM_TRANSACTION_ENDPOINT,
  createNewTransactionTypes,
  FundingSources,
  GET_BANK_ACCOUNT_DETAILS_ENDPOINT,
  GET_MSK_ENDPOINT,
  GET_TRANSACTION_BY_QUERY_CODE_ENDPOINT,
  getBankAccountDetailsTypes,
  getTransactionByQueryCodeTypes,
  PROCEED_TRANSACTION,
  SECURE_3D_SPC_CB_COMMON_ENDPOINT,
  TransactionStatuses,
  UserAccountStatuses,
  GET_RECIPIENT_VERIFICATION_ENDPOINT,
  TransactionOrigins,
  recipientVerificationAction,
  PROCEED_TRANSACTION_TRUSTPAY,
  SEND_OTP_CODE_ENDPOINT,
  otpSendCodeTypes,
  otpVerifyCodeTypes,
  VERIFY_OTP_CODE_ENDPOINT,
  CHECK_OTP_REQUIRED_ENDPOINT,
  otpCheckVerificationRequiredTypes,
  mpinValidateTypes,
  PayoutTypesCategories,
} from "../transfer.constants";
import {
  bicSelector,
  cashCodeSelector,
  complianceRulesSelector,
  creditCardSelector,
  fundingSourceSelector,
  queryByCodePayloadSelector,
  transactionByQueryCodeSelector,
  transactionNewResponseSelector,
  priceResponseSelector,
  receiptDataSelector,
  transferDetailsSelector,
} from "../transfer.selectors";
import {
  checkComplianceActions,
  getBankAccountDetailsActions,
  getTransactionByQueryCodeActions,
  setQueryByCodePayloadAction,
  setSecure3dInfoAction,
  storeComplianceRuleDataAction,
  getRecipientVerificationAction,
  setResipientAccountVerifiedAction,
  otpSendCodeAction,
  otpVerifyCodeAction,
  otpSetResponseMessage,
  otpSetErrorMessage,
  otpSetResponseFlag,
  otpCheckVerificationRequiredAction,
} from "../transfer.actions";
import {
  IComplianceRule,
  IComplianceRuleWithPriority,
} from "../transfer.types";
import { createCreditCardActions } from "../../user/user.actions";
import osuService from "../../../services/osu.service";
import { getRecipientSelectorById } from "../../recipient/recipients.selectors";

const {
  BANK_TRANSFER,
  BARCLAY_CREDIT_CARD,
  CASH,
  CHECKOUT_CREDIT_CARD,
  CREDIT_CARD,
  GOOGLE_PAY,
  IDEAL,
  PAYOSU,
  TRUST_PAY_CREDIT_CARD,
  MAGIC_PAY_CREDIT_CARD,
} = FundingSources;
const {
  PROCESSING,
  FS_DEINED,
  FAIL_FS_CHARGED,
  FAIL,
  COMPLIANCE_REVIEW,
  FS_CHARGED,
  TELLER_APPROVED,
  COMPLETE_SUCCESS,
  CANCELLED,
  PRE_TXN,
  COMPLIANCE_FAILED,
  FS_IN_PROCESS,
  BLACKLIST_REVIEW,
} = TransactionStatuses;
const {
  TRANSACTION_FAILED,
  TRANSACTION_CARD_DECLINED,
  TRANSACTION_UNDER_REVIEW,
  UPLOAD_DOCUMENTS,
  TRANSACTION_CANCELLED,
  PENDING_TRANSACTION,
  TRANSACTION_SUBMITTED,
  COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN,
  LAST_TRANSACTIONS,
} = moneyTransferRoutes;

const getPriorityRule = (
  rules: IComplianceRule[],
): IComplianceRuleWithPriority => {
  try {
    const rulesWithPriority =
      rules &&
      rules.map((rule: IComplianceRule) => ({
        ...rule,
        priority: ComplianceRulesPriority[rule.action],
      }));
    return sortBy(rulesWithPriority, "priority")[0] || {};
  } catch (err) {
    throw err;
  }
};

function* getRecipientVerificationBodyGetter(
  payload?: Partial<{ accountNumber: string; bankCode: string }>,
) {
  const { country } = yield select(userDataSelector);
  const {
    recipient: { phoneNumber, recipientId },
    payoutType,
  } = yield select(transferDetailsSelector);

  switch (payoutType) {
    case PayoutTypesCategories.MOBILE_TOPUP:
    case PayoutTypesCategories.MOBILE_TOP_UP: {
      return {
        data: {
          recipientId,
          payoutType,
          country,
          phoneNumber,
          originType: TransactionOrigins.WEB,
        },
      };
    }
    case PayoutTypesCategories.BANK_ACCOUNT: {
      if (!(!isNil(payload) && payload.accountNumber && payload.bankCode)) {
        throw new Error("Bank account details are required");
      }

      return {
        data: {
          recipientId,
          payoutType,
          country,
          accountNumber: payload.accountNumber,
          bankCode: payload.bankCode,
          originType: TransactionOrigins.WEB,
        },
      };
    }
    default: {
      throw new Error("Payout type is not supported");
    }
  }
}

function* handleAccountVerification({ payload }: any) {
  yield put(setResipientAccountVerifiedAction(payload));
}

function* handleAccountVerificatioFailed({ payload }: any) {
  yield put(setResipientAccountVerifiedAction(payload));
}

const getVerifiedRecipientAccountSage = fork(requestSagaHandler.post, {
  paramsGetter: getRecipientVerificationBodyGetter,
  actions: getRecipientVerificationAction,
  types: recipientVerificationAction,
  url: GET_RECIPIENT_VERIFICATION_ENDPOINT,
  spinner: Spinners.LOADING_PAYOUT_TYPE_DETAILS_SPINNER,
});

function* getBankAccountDetailsBodyGetter() {
  const { country } = yield select(userDataSelector);

  return {
    data: {
      country,
    },
  };
}

function* handleTransactionNewSuccess() {
  const fundingSource = yield select(fundingSourceSelector);

  switch (fundingSource) {
    case CASH: {
      yield put(push(TRANSACTION_SUBMITTED));
      break;
    }
    case MAGIC_PAY_CREDIT_CARD:
    case TRUST_PAY_CREDIT_CARD:
      yield call(runBarclaySaga);
      break;
    case BARCLAY_CREDIT_CARD:
      toast.info({
        message:
          "For security reason you are required to enter your card details again and save it for future use.",
      });
      yield call(runBarclaySaga);
      break;
    case CREDIT_CARD:
      yield call(runSagePaySaga);
      break;
    case CHECKOUT_CREDIT_CARD:
    case IDEAL: {
      yield call(runCheckoutSaga, fundingSource);
      break;
    }
    case GOOGLE_PAY: {
      yield call(handleWithGooglePay);
      break;
    }
    case PAYOSU: {
      yield call(runPayOsuSaga);
      break;
    }
  }
}

function* handleWithGooglePay() {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));
    const cardIdentifier = yield select(cashCodeSelector);
    yield confirmTransactionSaga({
      cardIdentifier,
      merchantSessionKey: "",
    });
    yield handleTransactionStatus();
  } catch (err) {
    console.log(err);
    yield put(push(TRANSACTION_CANCELLED));
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}

function* handleTransactionNewFailure({ error }: any) {
  const userStatus = yield get(error, "userStatus", "");
  const complianceRules = yield get(error, "failedComplianceRules");

  const { FAILED, PENDING_REVIEW } = UserAccountStatuses;

  switch (userStatus) {
    case FAILED:
      yield put(push(moneyTransferRoutes.ACCOUNT_BLOCKED));
      break;
    case PENDING_REVIEW:
      yield put(push(moneyTransferRoutes.ACCOUNT_UNDER_REVIEW));
      break;
  }

  if (complianceRules) {
    yield handleComplianceRules(complianceRules);
  }
}

function* handleComplianceRules(complianceRules: IComplianceRule[]) {
  const { REQUIRE_DOC_MANUAL, REQUIRE_DOC, BLOCK } = ComplianceRules;
  const complianceRule = getPriorityRule(complianceRules); // get the priority of the rule

  const requiredDocuments = complianceRule.requiredDocuments.map(
    (doc: any) => ({
      documentType: doc,
    }),
  );

  yield put(
    storeComplianceRuleDataAction({ complianceRule, ...requiredDocuments }),
  );

  switch (complianceRule.action) {
    case BLOCK: {
      yield put(push(moneyTransferRoutes.TRANSACTION_CANCELLED));
      break;
    }
    case REQUIRE_DOC: {
      yield put(push(moneyTransferRoutes.UPLOAD_DOCUMENTS));
      break;
    }
    case REQUIRE_DOC_MANUAL: {
      yield put(push(moneyTransferRoutes.UPLOAD_DOCUMENTS));
      break;
    }
    default:
      yield put(push(moneyTransferRoutes.TRANSACTION_CANCELLED));
      break;
  }
}

function* getCreditCardDetailsSaga() {
  try {
    const {
      name: cardholderName,
      number: cardNumber,
      cvc: securityCode,
      expiryDate,
      existingCard,
      newCard,
      token,
    } = yield select(creditCardSelector);

    return {
      cardholderName,
      cardNumber,
      securityCode,
      expiryDate,
      existingCard,
      newCard,
      token,
    };
  } catch (err) {
    console.log(err);
  }
}

function* getMerchantSessionKeySaga() {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));
    const {
      data: { sessionKey },
    } = yield call(api.post, GET_MSK_ENDPOINT, {
      organizationId: environment.organizationId,
    });

    return sessionKey;
  } catch (err) {
    console.log(err);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}

function* addCardSaga(cardIdentifier: string) {
  const userId = yield select(userIdSelector);
  const { name, expiryDate, issuer, ...card } = yield select(
    creditCardSelector,
  );
  const fundingSource = yield select(fundingSourceSelector);
  const fundingSourceProcessorType = yield select(paymentProcessorSelector);

  const lastFourDigits = card.number
    .substr(card.number.length - 4)
    .padStart(card.number.length, "*");

  yield put(
    createCreditCardActions.request({
      userId,
      userCardData: {
        cardholderName: name,
        expirationMonth: expiryDate.slice(0, 2),
        expirationYear: expiryDate.slice(2),
        issuer,
        tokenizedCard: cardIdentifier,
        fundingSource,
        fundingSourceProcessorType,
        lastFourDigits,
      },
    }),
  );
}

function* confirmTransactionSaga(payload: {
  cardIdentifier?: string;
  merchantSessionKey?: string;
  existingCard?: boolean;
  newCard?: boolean;
  strongCustomerAuthenticationSagePay?: any;
}) {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));
    const { transactionId } = yield select(transactionNewResponseSelector);

    const agentId = yield select(agentIdSelector);
    const fundingSource = yield select(fundingSourceSelector);

    let advancedOptions = {};

    if (fundingSource === FundingSources.CHECKOUT_CREDIT_CARD) {
      const { newCard, ...cardDetails } = yield select(creditCardSelector);

      advancedOptions =
        cardDetails && cardDetails.number
          ? {
              number: cardDetails.number,
              expiry_month: cardDetails.expiryMonth,
              expiry_year: cardDetails.expiryYear,
            }
          : {};
    }

    const bic = fundingSource === IDEAL ? yield select(bicSelector) : undefined;

    const {
      data: { acsUrl, paReq, cReq, status, message, md, fsProcessStatus },
    } = yield call(api.post, CONFIRM_TRANSACTION_ENDPOINT, {
      organizationId: environment.organizationId,
      transactionId,
      existingCard: payload.existingCard,
      newCard: payload.newCard,
      agentId,
      bic, // BIC required for IDEAL
      ...payload,
      ...advancedOptions,
    });

    if (status === TransactionStatuses.FAIL) {
      yield put(push(TRANSACTION_CANCELLED));
      toast.error({ message });
      return;
    }

    return { acsUrl, paReq, creq: cReq, transactionId, md, fsProcessStatus };
  } catch (err) {
    console.log(err);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}

async function payByOsu({ trx, user, price }: any) {
  console.log("[OSU]: TRX - ", { price, trx });

  const payosu = (window as any).payosu;

  const senderToPay = get(price, "senderToPaySRC");
  const sourceAmount = get(price, "amountSentSRC");

  if (payosu) {
    return new Promise((resolve, reject) => {
      const options = {
        buyerFirstName: user.firstName,
        buyerLastName: user.lastName,
        buyerEmail: user.email,
        buyerPostCode: user.zipCode,
        buyerAddress: user.address,
        successURL: location.pathname + "?osu=success",
        paymentCurrency: trx.sendCurrency,
        apiKey: environment.payosuKey,
        paymentAmount:
          parseFloat(senderToPay || sourceAmount || trx.amount) * 100,
        vertical: "money transfer",
        paymentReference: trx.depositCode,
      };
      return payosu.pay(options, async (err: Error | string, data: string) => {
        try {
          const payload = {
            status: err ? "FAIL" : "SUCCESS",
            transactionId: trx.transactionId,
            paymentReference: trx.depositCode,
            organizationId: trx.organizationId,
          };

          await osuService.paymentCallback(payload);

          if (err) {
            return reject(err);
          } else {
            return resolve(data);
          }
        } catch (e) {
          return reject(e);
        }
      });
    });
  }

  throw new Error("Can not process payment.");
}

function* runPayOsuSaga() {
  const user = yield select(userDataSelector);
  const price = yield select(priceResponseSelector);
  const trx = yield select(transactionNewResponseSelector);

  try {
    console.log("[TRX]: Spinner - ", true);
    yield delay(250);
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));
    yield call(payByOsu, { trx, user, price });
    yield put(
      setQueryByCodePayloadAction({
        queryType: "DEPOSIT",
        queryCode: trx.depositCode,
      }),
    );
    yield proceedTransaction();
  } catch (e) {
    console.log("[OSU]: Error - ", e);
    yield put(push(TRANSACTION_CANCELLED));
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}

function* runSagePaySaga() {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));
    const {
      existingCard,
      newCard,
      ...cardDetails
    } = yield getCreditCardDetailsSaga();

    const strongCustomerAuthenticationSagePay = {
      browserJavascriptEnabled: "true",
      browserJavaEnabled: "false",
      browserLanguage: window.navigator.language,
      browserColorDepth: window.screen.colorDepth,
      browserScreenHeight: window.screen.height,
      browserScreenWidth: window.screen.width,
      browserTZ: new Date().getTimezoneOffset(),
      browserUserAgent: window.navigator.userAgent,
      challengeWindowSize: "FullScreen",
      transType: "GoodsAndServicePurchase",
    };

    const merchantSessionKey = yield getMerchantSessionKeySaga();
    const cardIdentifier = yield call(
      !cardDetails.token ? tokenizeSagepayCard : verifyReusableSagepayCard,
      {
        cardDetails,
        merchantSessionKey,
      },
    );

    if (newCard) {
      yield addCardSaga(cardIdentifier);
    }

    yield confrimAndRun3DsecureSaga({
      cardIdentifier,
      merchantSessionKey,
      newCard,
      existingCard,
      SPCallbackUrl: SECURE_3D_SPC_CB_COMMON_ENDPOINT,
      strongCustomerAuthenticationSagePay,
    });
  } catch (err) {
    console.log(err);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}

function* tokenizeSagepayCard({
  cardDetails,
  merchantSessionKey,
}: {
  cardDetails: {
    cardholderName: string;
    cardNumber: string;
    securityCode: string;
    expiryDate: string;
  };
  merchantSessionKey: string;
}) {
  const { cardIdentifier: id } = yield new Promise((resolve, reject) => {
    (window as any).sagepayOwnForm({ merchantSessionKey }).tokeniseCardDetails({
      cardDetails,
      onTokenised: (result: any) => {
        if (result.success) {
          resolve(result);
        } else {
          const message = get(result, "errors[0].message");
          reject({ message });
          toast.error({ message });
        }
      },
    });
  });

  return id;
}

function* tokenizeCheckoutCard(cardDetails: {
  name: string;
  number: string;
  cvc: string;
  expiryMonth: string;
  expiryYear: string;
}) {
  const tokenPayload = {
    type: "card",
    number: cardDetails.number,
    expiry_month: cardDetails.expiryMonth,
    expiry_year: cardDetails.expiryYear,
    cvv: cardDetails.cvc,
  };
  const { data } = yield call(
    Axios.post,
    `${environment.checkoutApiUrl}/tokens`,
    tokenPayload,
    {
      headers: {
        Authorization: environment.gatewayMerchantId,
        "Content-type": "application/json",
      },
    },
  );
  return data.token;
}

function* verifyReusableSagepayCard({
  cardDetails,
  merchantSessionKey,
}: {
  cardDetails: { token: string; securityCode: string };
  merchantSessionKey: string;
}) {
  const id = yield new Promise((res, rej) => {
    sagepayOwnForm({ merchantSessionKey }).activateReusableCardIdentifier({
      reusableCardIdentifier: cardDetails.token,
      securityCode: cardDetails.securityCode,
      onActivated: (result: any) => {
        if (result.success === true) {
          res(cardDetails.token);
        } else {
          rej(result);
        }
      },
    });
  });

  return id;
}

function* runCheckoutSaga(fundingSource: string) {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));
    if (fundingSource === IDEAL) {
      yield confrimAndRun3DsecureSaga({});
      return;
    }

    const { newCard, ...cardDetails } = yield select(creditCardSelector);

    if (newCard) {
      const cardIdentifier = yield call(tokenizeCheckoutCard, cardDetails);
      yield addCardSaga(cardIdentifier);
    }

    yield confrimAndRun3DsecureSaga({
      SPCallbackUrl: SECURE_3D_SPC_CB_COMMON_ENDPOINT,
    });
  } catch (err) {
    console.log(err.message);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}
function* runBarclaySaga() {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));

    // const { newCard, ...cardDetails } = yield select(creditCardSelector);
    // const cardIdentifier = yield call(tokenizeCheckoutCard, cardDetails);

    // if (newCard) {
    //   yield addCardSaga(cardIdentifier);
    // }

    yield confrimAndRun3DsecureSaga({
      SPCallbackUrl: SECURE_3D_SPC_CB_COMMON_ENDPOINT,
    });
  } catch (err) {
    console.log(err.message);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}
function* confrimAndRun3DsecureSaga(params: {
  cardIdentifier?: string;
  merchantSessionKey?: string;
  existingCard?: boolean;
  newCard?: boolean;
  SPCallbackUrl?: string;
  strongCustomerAuthenticationSagePay?: any;
}) {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));

    const {
      cardIdentifier,
      merchantSessionKey = "",
      SPCallbackUrl,
      strongCustomerAuthenticationSagePay,
    } = params;

    const {
      acsUrl,
      paReq,
      creq,
      transactionId,
      md,
      fsProcessStatus,
    } = yield confirmTransactionSaga({
      cardIdentifier,
      existingCard: params.existingCard,
      newCard: params.newCard,
      merchantSessionKey,
      strongCustomerAuthenticationSagePay,
    });

    if (fsProcessStatus === "SUCCESS") {
      yield put(push(TRANSACTION_SUBMITTED));
      return;
    } else if (fsProcessStatus === "FAIL") {
      yield put(push(TRANSACTION_FAILED));
      return;
    } else if (fsProcessStatus === "ADDITIONAL_AUTH") {
      if (!acsUrl) {
        yield put(push(TRANSACTION_CARD_DECLINED));
        return;
      }

      yield run3DsecureSaga({
        acsUrl,
        paReq,
        creq,
        transactionId,
        SPCallbackUrl,
        md,
      });
    }
  } catch (err) {
    yield put(push(TRANSACTION_CANCELLED));
    console.log(err);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}

function* run3DsecureSaga(payload: {
  acsUrl: string;
  paReq?: string;
  creq?: string;
  transactionId: string;
  SPCallbackUrl?: string;
  md?: string;
}) {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));

    yield put(setSecure3dInfoAction(payload));
    const fundingSource = yield select(fundingSourceSelector);
    const {
      SECURE_3D,
      SECURE_3D_IFRAME,
      SECURE_3D_CHECKOUT,
      SECURE_3D_TRUSTPAY,
      // SECURE_3D_CHECKOUT_WINDOW,
    } = moneyTransferRoutes;

    if (fundingSource === CHECKOUT_CREDIT_CARD) {
      yield put(push(SECURE_3D_CHECKOUT));
    } else if (fundingSource === TRUST_PAY_CREDIT_CARD) {
      yield put(push(SECURE_3D_TRUSTPAY));
    } else if (fundingSource === MAGIC_PAY_CREDIT_CARD) {
      yield put(push(SECURE_3D_TRUSTPAY));
    } else if (fundingSource === IDEAL) {
      yield put(push(SECURE_3D));
    } else {
      yield put(push(SECURE_3D_IFRAME));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}

function* proceedTransactionTrustPay() {
  yield call(proceedTransaction, 5);
}

function* proceedTransaction(countdownTimer = 60) {
  const chan = yield call(countdown, countdownTimer);

  const breakStatuses = [
    // FS_CHARGED,
    FS_DEINED,
    FAIL,
    FAIL_FS_CHARGED,
    PROCESSING,
    COMPLIANCE_REVIEW,
    BLACKLIST_REVIEW,
    // TELLER_APPROVED,
  ];

  let attemptsWithTellerApprovedStatus = 0;

  try {
    while (1) {
      yield take(chan);

      const payload = yield select(queryByCodePayloadSelector);
      if (!payload) {
        break;
      }
      yield put(getTransactionByQueryCodeActions.request(payload));

      const transactionData = yield select(transactionByQueryCodeSelector);
      const status = get(transactionData, [
        "transactionDetails",
        "transactionStatus",
      ]);
      const fundingSource = get(transactionData, [
        "transactionDetails",
        "fundingSource",
      ]);

      if (status === TELLER_APPROVED) {
        attemptsWithTellerApprovedStatus++;
        if (attemptsWithTellerApprovedStatus > 13) {
          break;
        }
      }

      if (breakStatuses.includes(status)) {
        break;
      }

      if (fundingSource === PAYOSU && status === FS_IN_PROCESS) {
        break;
      }
    }
  } finally {
    yield take([
      getTransactionByQueryCodeTypes[SUCCESS],
      getTransactionByQueryCodeTypes[FAILURE],
    ]);
    yield call(handleTransactionStatus);
    if (yield cancelled()) {
      chan.close();
      const transaction = yield select(transactionByQueryCodeSelector);
      const fundingSource = get(transaction, [
        "transactionDetails",
        "fundingSource",
      ]);
      if (fundingSource === IDEAL) {
        toast.info({
          message:
            "Payment made by iDeal may take up to 10 minutes. Please check the status of your transaction after 10 minutes retry if unsuccessful.",
        });
      }
    }
  }
}

function* handleComplianceDocManual() {
  const complianceDocs = get(
    yield select(transactionByQueryCodeSelector),
    "transactionDetails.complianceRequiredDocuments",
  );
  if (!complianceDocs) {
    yield put(push(TRANSACTION_UNDER_REVIEW));
    return;
  }

  const complianceStatus = get(
    yield select(transactionByQueryCodeSelector),
    "transactionDetails.complianceStatus",
  );

  if (complianceStatus === COMPLIANCE_RULE_FAILED) {
    const { DOCUMENT_MANUAL } = ComplianceRules;

    const userDocTypes = yield select(userDocTypesSelector);

    const requiredDocuments = difference(complianceDocs, userDocTypes).map(
      doc => {
        return {
          documentType: doc,
        };
      },
    );

    yield put(
      storeComplianceRuleDataAction({
        requiredDocuments,
        action: DOCUMENT_MANUAL,
      }),
    );
    const isUserHasRequiredDocuments = yield select(
      isUserHasRequiredComplianceDocumentsSelector,
    );

    if (!isUserHasRequiredDocuments) {
      setDocumentUploadRedirectRoute(COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN);
      yield put(push(UPLOAD_DOCUMENTS));
      return;
    }

    yield put(push(COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN));
  }
}

function* completeTransactionWithoutDocumentsSaga() {
  const { DOCUMENT_MANUAL, REQUIRE_DOC } = ComplianceRules;
  const activeRule = yield select(complianceRulesSelector);
  yield put(toggleModal(Modals.SKIP_UPLOAD_CONFIRMATION_MODAL, false));
  switch (activeRule.action) {
    case REQUIRE_DOC:
    case DOCUMENT_MANUAL: {
      yield put(push(TRANSACTION_UNDER_REVIEW));
      break;
    }
    default: {
      yield put(push(TRANSACTION_CANCELLED));
    }
  }
}

function* handleTransactionStatus() {
  const transaction = yield select(transactionByQueryCodeSelector);
  const transactionStatus = get(transaction, [
    "transactionDetails",
    "transactionStatus",
  ]);

  switch (transactionStatus) {
    case TELLER_APPROVED:
    case PROCESSING:
    case FS_IN_PROCESS: {
      yield put(push(PENDING_TRANSACTION));
      break;
    }
    case CANCELLED:
    case COMPLIANCE_FAILED:
    case FAIL:
    case FAIL_FS_CHARGED:
    case FS_DEINED: {
      yield put(push(TRANSACTION_CANCELLED));
      break;
    }
    case FS_CHARGED:
    case COMPLETE_SUCCESS:
    case PRE_TXN: {
      yield put(push(TRANSACTION_SUBMITTED));
      break;
    }
    case BLACKLIST_REVIEW:
    case COMPLIANCE_REVIEW: {
      yield put(push(COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN));
      break;
    }
    default: {
      yield put(push(TRANSACTION_CANCELLED));
    }
  }
}

const getBankAccountDetailsSaga = fork(requestSagaHandler.post, {
  paramsGetter: getBankAccountDetailsBodyGetter,
  actions: getBankAccountDetailsActions,
  types: getBankAccountDetailsTypes,
  url: GET_BANK_ACCOUNT_DETAILS_ENDPOINT,
  spinner: Spinners.TRANSACTION_NEW_SPINNER,
  navigate: {
    SUCCESS: moneyTransferRoutes.BANK_TRANSFER_SUCCESS,
  },
});

const getTransactionByQueryCodeBodyGetter = (params: {
  queryType: string;
  queryCode: string;
}) => ({
  data: {
    organizationId: environment.organizationId,
    ...params,
  },
});

const checkComplianceBodyGetter = (transaction: {
  amount: string;
  payoutType: string;
  recipientId: string;
  sendCurrency: string;
  senderId: string;
  transactionOrigin: string;
}) => ({
  data: {
    amount: transaction.amount,
    payoutType: transaction.payoutType,
    recipientId: transaction.recipientId,
    sendCurrency: transaction.sendCurrency,
    senderId: transaction.senderId,
    transactionOrigin: transaction.transactionOrigin,
  },
});

const getTransactionByQueryCodeSaga = fork(requestSagaHandler.post, {
  paramsGetter: getTransactionByQueryCodeBodyGetter,
  actions: getTransactionByQueryCodeActions,
  types: getTransactionByQueryCodeTypes,
  url: GET_TRANSACTION_BY_QUERY_CODE_ENDPOINT,
  // spinner: Spinners.TRANSACTIONS_SPINNER,
});

const sendOtpCodeSage = fork(requestSagaHandler.get, {
  actions: otpSendCodeAction,
  types: otpSendCodeTypes,
  url: SEND_OTP_CODE_ENDPOINT,
});

const verifyOtpCodeSage = fork(requestSagaHandler.post, {
  actions: otpVerifyCodeAction,
  paramsGetter: (code: string) => ({ data: { code } }),
  types: otpVerifyCodeTypes,
  url: VERIFY_OTP_CODE_ENDPOINT,
});

const checkVerificationRequiredSage = fork(requestSagaHandler.get, {
  actions: otpCheckVerificationRequiredAction,
  types: otpCheckVerificationRequiredTypes,
  url: CHECK_OTP_REQUIRED_ENDPOINT,
});

function* handleOtpSendingSuccess({ payload: { message } }: any) {
  yield put(otpSetResponseMessage(message));
}

function* handleOtpSendingFailed({ error: { data } }: any) {
  yield all([
    put(otpSetErrorMessage(data.message)),
    put(otpSetResponseFlag("FAILED")),
    put(push(LAST_TRANSACTIONS)),
  ]);
}

function* handleOtpVerificationSuccess() {
  yield put(otpSetResponseFlag("SUCCESS"));
}

function* handleOtpVerificationFailed({ error: { data } }: any) {
  if (data.attemptsLimitReached) {
    yield all([
      put(otpSetErrorMessage(data.message)),
      put(otpSetResponseFlag("FAILED")),
      put(push(LAST_TRANSACTIONS)),
    ]);
  } else {
    yield put(otpSetErrorMessage(data.message));
  }
}

const transferVerificationSaga = all([
  getBankAccountDetailsSaga,
  getTransactionByQueryCodeSaga,
  getVerifiedRecipientAccountSage,
  sendOtpCodeSage,
  verifyOtpCodeSage,
  checkVerificationRequiredSage,
  takeEvery(
    recipientVerificationAction[SUCCESS],
    handleAccountVerificatioFailed,
  ),
  takeEvery(
    recipientVerificationAction[FAILURE],
    handleAccountVerificatioFailed,
  ),
  takeEvery(createNewTransactionTypes[SUCCESS], handleTransactionNewSuccess),
  takeEvery(createNewTransactionTypes[FAILURE], handleTransactionNewFailure),
  takeEvery(otpSendCodeTypes[SUCCESS], handleOtpSendingSuccess),
  takeEvery(otpSendCodeTypes[FAILURE], handleOtpSendingFailed),
  takeEvery(otpVerifyCodeTypes[SUCCESS], handleOtpVerificationSuccess),
  takeEvery(otpVerifyCodeTypes[FAILURE], handleOtpVerificationFailed),
  takeEvery(mpinValidateTypes[SUCCESS], handleOtpVerificationSuccess),
  takeEvery(mpinValidateTypes[FAILURE], handleOtpVerificationFailed),
  takeEvery(PROCEED_TRANSACTION, proceedTransaction),
  takeEvery(PROCEED_TRANSACTION_TRUSTPAY, proceedTransactionTrustPay),
  takeEvery(
    COMPLETE_TRANSACTION_WITHOUT_DOCS,
    completeTransactionWithoutDocumentsSaga,
  ),
]);

export default transferVerificationSaga;
