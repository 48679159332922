import { RouteComponentProps } from "react-router";

import { IRecipient } from "../recipient/recipient.types";

import { TransferDetailsConstants } from "./transfer.constants";

const {
  IFSC_BANK_DETAILS,
  RECIPIENT_VALIDATION,
  OTP_ERROR_TEXT,
  OTP_RESPONSE_FLAG,
  OTP_RESPONSE_TEXT,
  OTP_IS_VERIFICATION_REQUIRED,
} = TransferDetailsConstants;

export interface ITransferData {
  destinationCountry?: string;
  payoutType?: string;
  fundingSource?: string;
  activeComplianceRule?: IComplianceRule[];
  [IFSC_BANK_DETAILS]?: IBankDetailsIfsc;
  transactionStartTime?: string;
  [RECIPIENT_VALIDATION]: null | IRecipientValidationData;
  [OTP_ERROR_TEXT]?: null | string;
  [OTP_RESPONSE_FLAG]?: null | string;
  [OTP_RESPONSE_TEXT]?: null | string;
  [OTP_IS_VERIFICATION_REQUIRED]?: boolean | null;
}

export interface IActiveComplianceRule {
  rule: string;
  title?: string;
  message: string;
  route: string;
  finalRoute?: string;
}

export interface IAppliedComplianceRule {
  complianceRule: IComplianceRule;
}

export interface IComplianceRule {
  action: string;
  actual: string;
  currency: string;
  id: string;
  limit: string;
  name: string;
  period: string;
  periodUnit: string;
  requiredDocuments: any;
  sliding: boolean;
  startDate: string;
  type: string;
}

export interface IComplianceRuleWithPriority extends IComplianceRule {
  priority: number;
}

export interface IGetCurrenciesRequest {
  payoutType: string;
  sourceCountry: string;
  destinationCountry: string;
  operatorId: string;
}

export interface IMoneyTransferStep extends RouteComponentProps {
  handleNext: () => void;
  userDocs?: any;
  priceResponse?: any;
  organizationDocs?: any;
}

export interface IPayoutFormCommonProps {
  recipient: IRecipient;
  children: any;
  submitCallback: (payload: {
    useOfFunds: string;
    useOfFundsOther?: string;
    sourceOfFunds: string;
    values?: any; // To pass any formik values from child forms
  }) => void;
  sourceOfFunds: string;
  useOfFunds: string;
  useOfFundsOther: string;
}

export interface IFundingSourceComponent {
  id: string;
  icon: string;
  name: string;
  order?: number;
  disabled?: boolean;
  subtitle?: string;
  description?: string;
  Component?: any;
}

export interface IVerficationDocument {
  documentType: any;
  preview?: string;
  file?: Blob | File;
  subFiles?: IVerficationDocument[];
}

export interface IBankDetailsIfsc {
  bankName: string;
  bankAddress: string;
  bankCity: string;
  bankState: string;
  bankDistrict: string;
  bankBranchName: string;
  ifscCode: string;
}

export interface IRecipientValidationData {
  actionType: VerificationActionTypeEnum;
  statusCode: number;
  message?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  network?: string;
}

export enum VerificationActionTypeEnum {
  NEED_UPDATE = "NEED_UPDATE",
  NO_NEED_UPDATE = "NO_NEED_UPDATE",
  PATCHED = "PATCHED",
  RECIPIENT_BLOCKED = "RECIPIENT_BLOCKED",
  SET_MANUALLY = "SET_MANUALLY",
}
