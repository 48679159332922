export const CountryData: {
  [countryCode: string]: any;
} = {
  AD: {
    phonePrefix: "+376",
    fullName: "Andorra",
    currencySign: "",
    currency: "EUR",
    countryCode: "AD",
    flagClass: "ad",
    utcOffset: "",
    latitude: 42.5,
    longitude: 1.5,
  },
  AE: {
    phonePrefix: "+971",
    fullName: "United Arab Emirates",
    currencySign: "NT$",
    currency: "AED",
    countryCode: "AE",
    flagClass: "ae",
    utcOffset: "+08:00",
    latitude: "0",
    longitude: "0",
  },
  US: {
    phonePrefix: "+1",
    fullName: "United States",
    currencySign: "$",
    currency: "USD",
    countryCode: "US",
    flagClass: "us",
    utcOffset: "-04:00",
    latitude: "37.090240",
    longitude: "-95.712891",
  },
  // AH: {
  //   phonePrefix: "+840",
  //   fullName: "Abkhazia",
  //   currencySign: "",
  //   currency: "ABK",
  //   countryCode: "AH",
  //   flagClass: "ah",
  //   utcOffset: "+04:00",
  //   latitude: "43.0005",
  //   longitude: "41.00501",
  // },
  AF: {
    phonePrefix: "+93",
    fullName: "Afghanistan",
    currencySign: "",
    currency: "AFN",
    countryCode: "AF",
    flagClass: "af",
    utcOffset: "",
    latitude: 33,
    longitude: 65,
  },
  AG: {
    phonePrefix: "+1268",
    fullName: "Antigua and Barbuda",
    currencySign: "",
    currency: "XCD",
    countryCode: "AG",
    flagClass: "ag",
    utcOffset: "",
    latitude: 17.05,
    longitude: -61.8,
  },
  AI: {
    phonePrefix: "+1264",
    fullName: "Anguilla",
    currencySign: "",
    currency: "XCD",
    countryCode: "AI",
    flagClass: "ai",
    utcOffset: "",
    latitude: 18.25,
    longitude: -63.16666666,
  },
  AL: {
    phonePrefix: "+355",
    fullName: "Albania",
    currencySign: "",
    currency: "ALL",
    countryCode: "AL",
    flagClass: "al",
    utcOffset: "",
    latitude: 41,
    longitude: 20,
  },
  AM: {
    phonePrefix: "+374",
    fullName: "Armenia",
    currencySign: "֏",
    currency: "AMD",
    countryCode: "AM",
    flagClass: "am",
    utcOffset: "+05:00",
    latitude: "0",
    longitude: "0",
  },
  AO: {
    phonePrefix: "+244",
    fullName: "Angola",
    currencySign: "",
    currency: "AOA",
    countryCode: "AO",
    flagClass: "ao",
    utcOffset: "",
    latitude: -12.5,
    longitude: 18.5,
  },
  AQ: {
    phonePrefix: "+672",
    fullName: "Antarctica",
    currencySign: "",
    countryCode: "AQ",
    flagClass: "aq",
    utcOffset: "",
    latitude: -90,
    longitude: 0,
  },
  AR: {
    phonePrefix: "+54",
    fullName: "Argentina",
    currencySign: "‎$",
    currency: "ARS",
    countryCode: "AR",
    flagClass: "ar",
    utcOffset: "-03:00",
    latitude: "0",
    longitude: "0",
  },
  AS: {
    phonePrefix: "+1684",
    fullName: "American Samoa",
    currencySign: "",
    currency: "USD",
    countryCode: "AS",
    flagClass: "as",
    utcOffset: "",
    latitude: -14.33333333,
    longitude: -170,
  },
  AT: {
    phonePrefix: "+43",
    fullName: "Austria",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "AT",
    flagClass: "at",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  AU: {
    phonePrefix: "+61",
    fullName: "Australia",
    currencySign: "‎$",
    currency: "AUD",
    countryCode: "AU",
    flagClass: "au",
    utcOffset: "+05:00",
    latitude: "0",
    longitude: "0",
  },
  AW: {
    phonePrefix: "+297",
    fullName: "Aruba",
    currencySign: "",
    currency: "AWG",
    countryCode: "AW",
    flagClass: "aw",
    utcOffset: "",
    latitude: 12.5,
    longitude: -69.96666666,
  },
  AX: {
    phonePrefix: "+358",
    fullName: "Åland Islands",
    currencySign: "",
    currency: "EUR",
    countryCode: "AX",
    flagClass: "ax",
    utcOffset: "",
    latitude: 60.116667,
    longitude: 19.9,
  },
  AZ: {
    phonePrefix: "+994",
    fullName: "Azerbaijan",
    currencySign: "₼",
    currency: "AZN",
    countryCode: "AZ",
    flagClass: "az",
    utcOffset: "+04:00",
    latitude: "0",
    longitude: "0",
  },
  BA: {
    phonePrefix: "+387",
    fullName: "Bosnia and Herzegovina",
    currencySign: "",
    currency: "BAM",
    countryCode: "BA",
    flagClass: "ba",
    utcOffset: "",
    latitude: 44,
    longitude: 18,
  },
  BB: {
    phonePrefix: "+1246",
    fullName: "Barbados",
    currencySign: "",
    currency: "BBD",
    countryCode: "BB",
    flagClass: "bb",
    utcOffset: "",
    latitude: 13.16666666,
    longitude: -59.53333333,
  },
  BD: {
    phonePrefix: "+880",
    fullName: "Bangladesh",
    currencySign: "‎৳",
    currency: "BDT",
    countryCode: "BD",
    flagClass: "bd",
    utcOffset: "+06:00",
    latitude: "23.684994",
    longitude: "90.356331",
  },
  BE: {
    phonePrefix: "+32",
    fullName: "Belgium",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "BE",
    flagClass: "be",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  BF: {
    phonePrefix: "+226",
    fullName: "Burkina Faso",
    currencySign: "",
    currency: "XOF",
    countryCode: "BF",
    flagClass: "bf",
    utcOffset: "",
    latitude: 13,
    longitude: -2,
  },
  BG: {
    phonePrefix: "+359",
    fullName: "Bulgaria",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "BG",
    flagClass: "bg",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  BH: {
    phonePrefix: "+973",
    fullName: "Bahrain",
    currencySign: "",
    currency: "BHD",
    countryCode: "BH",
    flagClass: "bh",
    utcOffset: "",
    latitude: 26,
    longitude: 50.55,
  },
  BI: {
    phonePrefix: "+257",
    fullName: "Burundi",
    currencySign: "",
    currency: "BIF",
    countryCode: "BI",
    flagClass: "bi",
    utcOffset: "",
    latitude: -3.5,
    longitude: 30,
  },
  BJ: {
    phonePrefix: "+229",
    fullName: "Benin",
    currencySign: "",
    currency: "XOF",
    countryCode: "BJ",
    flagClass: "bj",
    utcOffset: "",
    latitude: 9.5,
    longitude: 2.25,
  },
  BL: {
    phonePrefix: "+590",
    fullName: "Saint Barthélemy",
    currencySign: "",
    currency: "EUR",
    countryCode: "BL",
    flagClass: "bl",
    utcOffset: "",
    latitude: 18.5,
    longitude: -63.41666666,
  },
  BM: {
    phonePrefix: "+1441",
    fullName: "Bermuda",
    currencySign: "",
    currency: "BMD",
    countryCode: "BM",
    flagClass: "bm",
    utcOffset: "",
    latitude: 32.33333333,
    longitude: -64.75,
  },
  BN: {
    phonePrefix: "+673",
    fullName: "Brunei",
    currencySign: "",
    currency: "BND",
    countryCode: "BN",
    flagClass: "bn",
    utcOffset: "",
    latitude: 4.5,
    longitude: 114.66666666,
  },
  BO: {
    phonePrefix: "+591",
    fullName: "Bolivia",
    currencySign: "",
    currency: "BOB",
    countryCode: "BO",
    flagClass: "bo",
    utcOffset: "",
    latitude: -17,
    longitude: -65,
  },
  BQ: {
    phonePrefix: "+599",
    fullName: "Caribbean Netherlands",
    currencySign: "",
    currency: "USD",
    countryCode: "BQ",
    flagClass: "bq",
    utcOffset: "",
    latitude: 12.18,
    longitude: -68.25,
  },
  BR: {
    phonePrefix: "+55",
    fullName: "Brazil",
    currencySign: "‎R$",
    currency: "BRL",
    countryCode: "BR",
    flagClass: "br",
    utcOffset: "-03:00",
    latitude: -10,
    longitude: -55,
  },
  BS: {
    phonePrefix: "+1242",
    fullName: "Bahamas",
    currencySign: "",
    currency: "BSD",
    countryCode: "BS",
    flagClass: "bs",
    utcOffset: "",
    latitude: 24.25,
    longitude: -76,
  },
  BT: {
    phonePrefix: "+975",
    fullName: "Bhutan",
    currencySign: "",
    currency: "BTN",
    countryCode: "BT",
    flagClass: "bt",
    utcOffset: "",
    latitude: 27.5,
    longitude: 90.5,
  },
  BV: {
    phonePrefix: "+47",
    fullName: "Bouvet Island",
    currencySign: "",
    currency: "NOK",
    countryCode: "BV",
    flagClass: "bv",
    utcOffset: "",
    latitude: -54.43333333,
    longitude: 3.4,
  },
  BW: {
    phonePrefix: "+267",
    fullName: "Botswana",
    currencySign: "",
    currency: "BWP",
    countryCode: "BW",
    flagClass: "bw",
    utcOffset: "",
    latitude: -22,
    longitude: 24,
  },
  BY: {
    phonePrefix: "+375",
    fullName: "Belarus",
    currencySign: "Br",
    currency: "BYN",
    countryCode: "BY",
    flagClass: "by",
    utcOffset: "+03:00",
    latitude: "0",
    longitude: "0",
  },
  BZ: {
    phonePrefix: "+501",
    fullName: "Belize",
    currencySign: "",
    currency: "BZD",
    countryCode: "BZ",
    flagClass: "bz",
    utcOffset: "",
    latitude: 17.25,
    longitude: -88.75,
  },
  CA: {
    phonePrefix: "+1",
    fullName: "Canada",
    currencySign: "‎C$",
    currency: "CAD",
    countryCode: "CA",
    flagClass: "ca",
    utcOffset: "-04:00",
    latitude: "56.130366",
    longitude: "-106.346771",
  },
  CC: {
    phonePrefix: "+61",
    fullName: "Cocos (Keeling) Islands",
    currencySign: "",
    currency: "AUD",
    countryCode: "CC",
    flagClass: "cc",
    utcOffset: "",
    latitude: -12.5,
    longitude: 96.83333333,
  },
  CD: {
    phonePrefix: "+243",
    fullName: "DR Congo",
    currencySign: "",
    currency: "CDF",
    countryCode: "CD",
    flagClass: "cd",
    utcOffset: "",
    latitude: 0,
    longitude: 25,
  },
  CF: {
    phonePrefix: "+236",
    fullName: "Central African Republic",
    currencySign: "",
    currency: "XAF",
    countryCode: "CF",
    flagClass: "cf",
    utcOffset: "",
    latitude: 7,
    longitude: 21,
  },
  CG: {
    phonePrefix: "+242",
    fullName: "Republic of the Congo",
    currencySign: "",
    currency: "XAF",
    countryCode: "CG",
    flagClass: "cg",
    utcOffset: "",
    latitude: -1,
    longitude: 15,
  },
  CH: {
    phonePrefix: "+41",
    fullName: "Switzerland",
    currencySign: "",
    currency: "CHF",
    countryCode: "CH",
    flagClass: "ch",
    utcOffset: "",
    latitude: 47,
    longitude: 8,
  },
  CI: {
    phonePrefix: "+225",
    fullName: "Ivory Coast",
    currencySign: "",
    currency: "XOF",
    countryCode: "CI",
    flagClass: "ci",
    utcOffset: "",
    latitude: 8,
    longitude: -5,
  },
  CK: {
    phonePrefix: "+682",
    fullName: "Cook Islands",
    currencySign: "",
    currency: "NZD",
    countryCode: "CK",
    flagClass: "ck",
    utcOffset: "",
    latitude: -21.23333333,
    longitude: -159.76666666,
  },
  CL: {
    phonePrefix: "+56",
    fullName: "Chile",
    currencySign: "",
    currency: "CLP",
    countryCode: "CL",
    flagClass: "cl",
    utcOffset: "",
    latitude: -30,
    longitude: -71,
  },
  CM: {
    phonePrefix: "+237",
    fullName: "Cameroon",
    currencySign: "",
    currency: "XAF",
    countryCode: "CM",
    flagClass: "cm",
    utcOffset: "",
    latitude: 6,
    longitude: 12,
  },
  CN: {
    phonePrefix: "+86",
    fullName: "China",
    currencySign: "‎¥",
    currency: "CNY",
    countryCode: "CN",
    flagClass: "cn",
    utcOffset: "+08:00",
    latitude: "0",
    longitude: "0",
  },
  CO: {
    phonePrefix: "+57",
    fullName: "Colombia",
    currencySign: "",
    currency: "COP",
    countryCode: "CO",
    flagClass: "co",
    utcOffset: "",
    latitude: 4,
    longitude: -72,
  },
  CR: {
    phonePrefix: "+506",
    fullName: "Costa Rica",
    currencySign: "",
    currency: "CRC",
    countryCode: "CR",
    flagClass: "cr",
    utcOffset: "",
    latitude: 10,
    longitude: -84,
  },
  CU: {
    phonePrefix: "+53",
    fullName: "Cuba",
    currencySign: "",
    currency: "CUC",
    countryCode: "CU",
    flagClass: "cu",
    utcOffset: "",
    latitude: 21.5,
    longitude: -80,
  },
  CV: {
    phonePrefix: "+238",
    fullName: "Cape Verde",
    currencySign: "",
    currency: "CVE",
    countryCode: "CV",
    flagClass: "cv",
    utcOffset: "",
    latitude: 16,
    longitude: -24,
  },
  CW: {
    phonePrefix: "+5999",
    fullName: "Curaçao",
    currencySign: "",
    currency: "ANG",
    countryCode: "CW",
    flagClass: "cw",
    utcOffset: "",
    latitude: 12.116667,
    longitude: -68.933333,
  },
  CX: {
    phonePrefix: "+61",
    fullName: "Christmas Island",
    currencySign: "",
    currency: "AUD",
    countryCode: "CX",
    flagClass: "cx",
    utcOffset: "",
    latitude: -10.5,
    longitude: 105.66666666,
  },
  CY: {
    phonePrefix: "+357",
    fullName: "Cyprus",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "CY",
    flagClass: "cy",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  CZ: {
    phonePrefix: "+420",
    fullName: "Czech Republic",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "CZ",
    flagClass: "cz",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  DE: {
    phonePrefix: "+49",
    fullName: "Germany",
    currencySign: "‎‎€",
    currency: "EUR",
    countryCode: "DE",
    flagClass: "de",
    utcOffset: "+01:00",
    latitude: "51.165691",
    longitude: "10.451526",
  },
  DJ: {
    phonePrefix: "+253",
    fullName: "Djibouti",
    currencySign: "",
    currency: "DJF",
    countryCode: "DJ",
    flagClass: "dj",
    utcOffset: "",
    latitude: 11.5,
    longitude: 43,
  },
  DK: {
    phonePrefix: "+45",
    fullName: "Denmark",
    currencySign: "",
    currency: "DKK",
    countryCode: "DK",
    flagClass: "dk",
    utcOffset: "",
    latitude: 56,
    longitude: 10,
  },
  DM: {
    phonePrefix: "+1767",
    fullName: "Dominica",
    currencySign: "",
    currency: "XCD",
    countryCode: "DM",
    flagClass: "dm",
    utcOffset: "",
    latitude: 15.41666666,
    longitude: -61.33333333,
  },
  DO: {
    phonePrefix: "+1809",
    fullName: "Dominican Republic",
    currencySign: "",
    currency: "DOP",
    countryCode: "DO",
    flagClass: "do",
    utcOffset: "",
    latitude: 19,
    longitude: -70.66666666,
  },
  DZ: {
    phonePrefix: "+213",
    fullName: "Algeria",
    currencySign: "",
    currency: "DZD",
    countryCode: "DZ",
    flagClass: "dz",
    utcOffset: "",
    latitude: 28,
    longitude: 3,
  },
  EC: {
    phonePrefix: "+593",
    fullName: "Ecuador",
    currencySign: "‎$",
    currency: "USD",
    countryCode: "EC",
    flagClass: "ec",
    utcOffset: "-05:00",
    latitude: "0",
    longitude: "0",
  },
  EE: {
    phonePrefix: "+372",
    fullName: "Estonia",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "EE",
    flagClass: "ee",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  EG: {
    phonePrefix: "+20",
    fullName: "Egypt",
    currencySign: "E£",
    currency: "EGP",
    countryCode: "EG",
    flagClass: "eg",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  EH: {
    phonePrefix: "+212",
    fullName: "Western Sahara",
    currencySign: "",
    currency: "MAD",
    countryCode: "EH",
    flagClass: "eh",
    utcOffset: "",
    latitude: 24.5,
    longitude: -13,
  },
  ER: {
    phonePrefix: "+291",
    fullName: "Eritrea",
    currencySign: "",
    currency: "ERN",
    countryCode: "ER",
    flagClass: "er",
    utcOffset: "",
    latitude: 15,
    longitude: 39,
  },
  ES: {
    phonePrefix: "+34",
    fullName: "Spain",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "ES",
    flagClass: "es",
    utcOffset: "+01:00",
    latitude: "40.463667",
    longitude: "-3.749220",
  },
  ET: {
    phonePrefix: "+251",
    fullName: "Ethiopia",
    currencySign: "",
    currency: "ETB",
    countryCode: "ET",
    flagClass: "et",
    utcOffset: "",
    latitude: 8,
    longitude: 38,
  },
  FI: {
    phonePrefix: "+358",
    fullName: "Finland",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "FI",
    flagClass: "fi",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  FJ: {
    phonePrefix: "+679",
    fullName: "Fiji",
    currencySign: "",
    currency: "FJD",
    countryCode: "FJ",
    flagClass: "fj",
    utcOffset: "",
    latitude: -18,
    longitude: 175,
  },
  FK: {
    phonePrefix: "+500",
    fullName: "Falkland Islands",
    currencySign: "",
    currency: "FKP",
    countryCode: "FK",
    flagClass: "fk",
    utcOffset: "",
    latitude: -51.75,
    longitude: -59,
  },
  FM: {
    phonePrefix: "+691",
    fullName: "Micronesia",
    currencySign: "",
    currency: "USD",
    countryCode: "FM",
    flagClass: "fm",
    utcOffset: "",
    latitude: 6.91666666,
    longitude: 158.25,
  },
  FO: {
    phonePrefix: "+298",
    fullName: "Faroe Islands",
    currencySign: "",
    currency: "DKK",
    countryCode: "FO",
    flagClass: "fo",
    utcOffset: "",
    latitude: 62,
    longitude: -7,
  },
  FR: {
    phonePrefix: "+33",
    fullName: "France",
    currencySign: "€",
    currency: "EUR",
    countryCode: "FR",
    flagClass: "fr",
    utcOffset: "+01:00",
    latitude: "46.227638",
    longitude: "2.213749",
  },
  GA: {
    phonePrefix: "+241",
    fullName: "Gabon",
    currencySign: "",
    currency: "XAF",
    countryCode: "GA",
    flagClass: "ga",
    utcOffset: "",
    latitude: -1,
    longitude: 11.75,
  },
  GB: {
    phonePrefix: "+44",
    fullName: "United Kingdom",
    currencySign: "£",
    currency: "GBP",
    countryCode: "GB",
    flagClass: "gb",
    utcOffset: "+00:00",
    latitude: "55.3781",
    longitude: "3.4360",
  },
  GD: {
    phonePrefix: "+1473",
    fullName: "Grenada",
    currencySign: "",
    currency: "XCD",
    countryCode: "GD",
    flagClass: "gd",
    utcOffset: "",
    latitude: 12.11666666,
    longitude: -61.66666666,
  },
  GE: {
    phonePrefix: "+995",
    fullName: "Georgia",
    currencySign: "‎₾",
    currency: "GEL",
    countryCode: "GE",
    flagClass: "ge",
    utcOffset: "+04:00",
    latitude: "0",
    longitude: "0",
  },
  GF: {
    phonePrefix: "+594",
    fullName: "French Guiana",
    currencySign: "",
    currency: "EUR",
    countryCode: "GF",
    flagClass: "gf",
    utcOffset: "",
    latitude: 4,
    longitude: -53,
  },
  GG: {
    phonePrefix: "+44",
    fullName: "Guernsey",
    currencySign: "£",
    currency: "GBP",
    countryCode: "GG",
    flagClass: "gg",
    utcOffset: "",
    latitude: 49.46666666,
    longitude: -2.58333333,
  },
  GH: {
    phonePrefix: "+233",
    fullName: "Ghana",
    currencySign: "‎GH₵",
    currency: "GHS",
    countryCode: "GH",
    flagClass: "gh",
    utcOffset: "+00:00",
    latitude: "7.946527",
    longitude: "1.023194",
  },
  GI: {
    phonePrefix: "+350",
    fullName: "Gibraltar",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "GI",
    flagClass: "gi",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  GL: {
    phonePrefix: "+299",
    fullName: "Greenland",
    currencySign: "",
    currency: "DKK",
    countryCode: "GL",
    flagClass: "gl",
    utcOffset: "",
    latitude: 72,
    longitude: -40,
  },
  GM: {
    phonePrefix: "+220",
    fullName: "Gambia",
    currencySign: "",
    currency: "GMD",
    countryCode: "GM",
    flagClass: "gm",
    utcOffset: "",
    latitude: 13.46666666,
    longitude: -16.56666666,
  },
  GN: {
    phonePrefix: "+224",
    fullName: "Guinea",
    currencySign: "",
    currency: "GNF",
    countryCode: "GN",
    flagClass: "gn",
    utcOffset: "",
    latitude: 11,
    longitude: -10,
  },
  GP: {
    phonePrefix: "+590",
    fullName: "Guadeloupe",
    currencySign: "",
    currency: "EUR",
    countryCode: "GP",
    flagClass: "gp",
    utcOffset: "",
    latitude: 16.25,
    longitude: -61.583333,
  },
  GQ: {
    phonePrefix: "+240",
    fullName: "Equatorial Guinea",
    currencySign: "",
    currency: "XAF",
    countryCode: "GQ",
    flagClass: "gq",
    utcOffset: "",
    latitude: 2,
    longitude: 10,
  },
  GR: {
    phonePrefix: "+30",
    fullName: "Greece",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "GR",
    flagClass: "gr",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  GS: {
    phonePrefix: "+500",
    fullName: "South Georgia",
    currencySign: "£",
    currency: "GBP",
    countryCode: "GS",
    flagClass: "gs",
    utcOffset: "",
    latitude: -54.5,
    longitude: -37,
  },
  GT: {
    phonePrefix: "+502",
    fullName: "Guatemala",
    currencySign: "",
    currency: "GTQ",
    countryCode: "GT",
    flagClass: "gt",
    utcOffset: "",
    latitude: 15.5,
    longitude: -90.25,
  },
  GU: {
    phonePrefix: "+1671",
    fullName: "Guam",
    currencySign: "",
    currency: "USD",
    countryCode: "GU",
    flagClass: "gu",
    utcOffset: "",
    latitude: 13.46666666,
    longitude: 144.78333333,
  },
  GW: {
    phonePrefix: "+245",
    fullName: "Guinea-Bissau",
    currencySign: "",
    currency: "XOF",
    countryCode: "GW",
    flagClass: "gw",
    utcOffset: "",
    latitude: 12,
    longitude: -15,
  },
  GY: {
    phonePrefix: "+592",
    fullName: "Guyana",
    currencySign: "",
    currency: "GYD",
    countryCode: "GY",
    flagClass: "gy",
    utcOffset: "",
    latitude: 5,
    longitude: -59,
  },
  HK: {
    phonePrefix: "+852",
    fullName: "Hong Kong",
    currencySign: "$",
    currency: "HKD",
    countryCode: "HK",
    flagClass: "hk",
    utcOffset: "+08:00",
    latitude: "0",
    longitude: "0",
  },
  HM: {
    phonePrefix: "+672",
    fullName: "Heard Island and McDonald Islands",
    currencySign: "",
    currency: "AUD",
    countryCode: "HM",
    flagClass: "hm",
    utcOffset: "",
    latitude: -53.1,
    longitude: 72.51666666,
  },
  HN: {
    phonePrefix: "+504",
    fullName: "Honduras",
    currencySign: "",
    currency: "HNL",
    countryCode: "HN",
    flagClass: "hn",
    utcOffset: "",
    latitude: 15,
    longitude: -86.5,
  },
  HR: {
    phonePrefix: "+385",
    fullName: "Croatia",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "HR",
    flagClass: "hr",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  HT: {
    phonePrefix: "+509",
    fullName: "Haiti",
    currencySign: "",
    currency: "HTG",
    countryCode: "HT",
    flagClass: "ht",
    utcOffset: "",
    latitude: 19,
    longitude: -72.41666666,
  },
  HU: {
    phonePrefix: "+36",
    fullName: "Hungary",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "HU",
    flagClass: "hu",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  ID: {
    phonePrefix: "+62",
    fullName: "Indonesia",
    currencySign: "",
    currency: "IDR",
    countryCode: "ID",
    flagClass: "id",
    utcOffset: "",
    latitude: -5,
    longitude: 120,
  },
  IE: {
    phonePrefix: "+353",
    fullName: "Ireland",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "IE",
    flagClass: "ie",
    utcOffset: "00:00",
    latitude: "0",
    longitude: "0",
  },
  IL: {
    phonePrefix: "+972",
    fullName: "Israel",
    currencySign: "₪",
    currency: "ILS",
    countryCode: "IL",
    flagClass: "il",
    utcOffset: "+03:00",
    latitude: "31.046051",
    longitude: "34.851612",
  },
  IM: {
    phonePrefix: "+44",
    fullName: "Isle of Man",
    currencySign: "£",
    currency: "GBP",
    countryCode: "IM",
    flagClass: "im",
    utcOffset: "",
    latitude: 54.25,
    longitude: -4.5,
  },
  IN: {
    phonePrefix: "+91",
    fullName: "India",
    currencySign: "₹",
    currency: "INR",
    countryCode: "IN",
    flagClass: "in",
    utcOffset: "+05:30",
    latitude: "20.593684",
    longitude: "78.962880",
  },
  IO: {
    phonePrefix: "+246",
    fullName: "British Indian Ocean Territory",
    currencySign: "",
    currency: "USD",
    countryCode: "IO",
    flagClass: "io",
    utcOffset: "",
    latitude: -6,
    longitude: 71.5,
  },
  IQ: {
    phonePrefix: "+964",
    fullName: "Iraq",
    currencySign: "",
    currency: "IQD",
    countryCode: "IQ",
    flagClass: "iq",
    utcOffset: "",
    latitude: 33,
    longitude: 44,
  },
  IR: {
    phonePrefix: "+98",
    fullName: "Iran",
    currencySign: "",
    currency: "IRR",
    countryCode: "IR",
    flagClass: "ir",
    utcOffset: "",
    latitude: 32,
    longitude: 53,
  },
  IS: {
    phonePrefix: "+354",
    fullName: "Iceland",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "IS",
    flagClass: "is",
    utcOffset: "00:00",
    latitude: "0",
    longitude: "0",
  },
  IT: {
    phonePrefix: "+39",
    fullName: "Italy",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "IT",
    flagClass: "it",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  JE: {
    phonePrefix: "+44",
    fullName: "Jersey",
    currencySign: "£",
    currency: "GBP",
    countryCode: "JE",
    flagClass: "je",
    utcOffset: "",
    latitude: 49.25,
    longitude: -2.16666666,
  },
  JM: {
    phonePrefix: "+1876",
    fullName: "Jamaica",
    currencySign: "",
    currency: "JMD",
    countryCode: "JM",
    flagClass: "jm",
    utcOffset: "",
    latitude: 18.25,
    longitude: -77.5,
  },
  JO: {
    phonePrefix: "+962",
    fullName: "Jordan",
    currencySign: "‎JOD",
    currency: "JOD",
    countryCode: "JO",
    flagClass: "jo",
    utcOffset: "+02:00",
    latitude: "30.585164",
    longitude: "36.238414",
  },
  JP: {
    phonePrefix: "+81",
    fullName: "Japan",
    currencySign: "¥",
    currency: "JPY",
    countryCode: "JP",
    flagClass: "jp",
    utcOffset: "+09:00",
    latitude: "0",
    longitude: "0",
  },
  KE: {
    phonePrefix: "+254",
    fullName: "Kenya",
    currencySign: "‎$",
    currency: "KES",
    countryCode: "KE",
    flagClass: "ke",
    utcOffset: "+03:00",
    latitude: "0",
    longitude: "0",
  },
  KG: {
    phonePrefix: "+996",
    fullName: "Kyrgyzstan",
    currencySign: "som",
    currency: "KGS",
    countryCode: "KG",
    flagClass: "kg",
    utcOffset: "+06:00",
    latitude: "0",
    longitude: "0",
  },
  KH: {
    phonePrefix: "+855",
    fullName: "Cambodia",
    currencySign: "‎៛",
    currency: "KHR",
    countryCode: "KH",
    flagClass: "kh",
    utcOffset: "+07:00",
    latitude: "0",
    longitude: "0",
  },
  KI: {
    phonePrefix: "+686",
    fullName: "Kiribati",
    currencySign: "",
    currency: "AUD",
    countryCode: "KI",
    flagClass: "ki",
    utcOffset: "",
    latitude: 1.41666666,
    longitude: 173,
  },
  KM: {
    phonePrefix: "+269",
    fullName: "Comoros",
    currencySign: "",
    currency: "KMF",
    countryCode: "KM",
    flagClass: "km",
    utcOffset: "",
    latitude: -12.16666666,
    longitude: 44.25,
  },
  KN: {
    phonePrefix: "+1869",
    fullName: "Saint Kitts and Nevis",
    currencySign: "",
    currency: "XCD",
    countryCode: "KN",
    flagClass: "kn",
    utcOffset: "",
    latitude: 17.33333333,
    longitude: -62.75,
  },
  KP: {
    phonePrefix: "+850",
    fullName: "North Korea",
    currencySign: "",
    currency: "KPW",
    countryCode: "KP",
    flagClass: "kp",
    utcOffset: "",
    latitude: 40,
    longitude: 127,
  },
  KR: {
    phonePrefix: "+82",
    fullName: "South Korea",
    currencySign: "",
    currency: "KRW",
    countryCode: "KR",
    flagClass: "kr",
    utcOffset: "",
    latitude: 37,
    longitude: 127.5,
  },
  KW: {
    phonePrefix: "+965",
    fullName: "Kuwait",
    currencySign: "",
    currency: "KWD",
    countryCode: "KW",
    flagClass: "kw",
    utcOffset: "",
    latitude: 29.5,
    longitude: 45.75,
  },
  KY: {
    phonePrefix: "+1345",
    fullName: "Cayman Islands",
    currencySign: "",
    currency: "KYD",
    countryCode: "KY",
    flagClass: "ky",
    utcOffset: "",
    latitude: 19.5,
    longitude: -80.5,
  },
  KZ: {
    phonePrefix: "+7",
    fullName: "Kazakhstan",
    currencySign: "₸",
    currency: "KZT",
    countryCode: "KZ",
    flagClass: "kz",
    utcOffset: "+06:00",
    latitude: "0",
    longitude: "0",
  },
  LA: {
    phonePrefix: "+856",
    fullName: "Laos",
    currencySign: "",
    currency: "LAK",
    countryCode: "LA",
    flagClass: "la",
    utcOffset: "",
    latitude: 18,
    longitude: 105,
  },
  LB: {
    phonePrefix: "+961",
    fullName: "Lebanon",
    currencySign: "",
    currency: "LBP",
    countryCode: "LB",
    flagClass: "lb",
    utcOffset: "",
    latitude: 33.83333333,
    longitude: 35.83333333,
  },
  LC: {
    phonePrefix: "+1758",
    fullName: "Saint Lucia",
    currencySign: "",
    currency: "XCD",
    countryCode: "LC",
    flagClass: "lc",
    utcOffset: "",
    latitude: 13.88333333,
    longitude: -60.96666666,
  },
  LI: {
    phonePrefix: "+423",
    fullName: "Liechtenstein",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "LI",
    flagClass: "li",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  LK: {
    phonePrefix: "+94",
    fullName: "Sri Lanka",
    currencySign: "₨",
    currency: "LKR",
    countryCode: "LK",
    flagClass: "lk",
    utcOffset: "+05:30",
    latitude: "7.873054",
    longitude: "80.771797",
  },
  LR: {
    phonePrefix: "+231",
    fullName: "Liberia",
    currencySign: "",
    currency: "LRD",
    countryCode: "LR",
    flagClass: "lr",
    utcOffset: "",
    latitude: 6.5,
    longitude: -9.5,
  },
  LS: {
    phonePrefix: "+266",
    fullName: "Lesotho",
    currencySign: "",
    currency: "LSL",
    countryCode: "LS",
    flagClass: "ls",
    utcOffset: "",
    latitude: -29.5,
    longitude: 28.5,
  },
  LT: {
    phonePrefix: "+370",
    fullName: "Lithuania",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "LT",
    flagClass: "lt",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  LU: {
    phonePrefix: "+352",
    fullName: "Luxembourg",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "LU",
    flagClass: "lu",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  LV: {
    phonePrefix: "+371",
    fullName: "Latvia",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "LV",
    flagClass: "lv",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  LY: {
    phonePrefix: "+218",
    fullName: "Libya",
    currencySign: "",
    currency: "LYD",
    countryCode: "LY",
    flagClass: "ly",
    utcOffset: "",
    latitude: 25,
    longitude: 17,
  },
  MA: {
    phonePrefix: "+212",
    fullName: "Morocco",
    currencySign: "",
    currency: "MAD",
    countryCode: "MA",
    flagClass: "ma",
    utcOffset: "",
    latitude: 32,
    longitude: -5,
  },
  MC: {
    phonePrefix: "+377",
    fullName: "Monaco",
    currencySign: "",
    currency: "EUR",
    countryCode: "MC",
    flagClass: "mc",
    utcOffset: "",
    latitude: 43.73333333,
    longitude: 7.4,
  },
  MD: {
    phonePrefix: "+373",
    fullName: "Moldova, Republic of",
    currencySign: "‎L",
    currency: "MDL",
    countryCode: "MD",
    flagClass: "md",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  ME: {
    phonePrefix: "+382",
    fullName: "Montenegro",
    currencySign: "",
    currency: "EUR",
    countryCode: "ME",
    flagClass: "me",
    utcOffset: "",
    latitude: 42.5,
    longitude: 19.3,
  },
  MF: {
    phonePrefix: "+590",
    fullName: "Saint Martin",
    currencySign: "",
    currency: "EUR",
    countryCode: "MF",
    flagClass: "mf",
    utcOffset: "",
    latitude: 18.08333333,
    longitude: -63.95,
  },
  MG: {
    phonePrefix: "+261",
    fullName: "Madagascar",
    currencySign: "",
    currency: "MGA",
    countryCode: "MG",
    flagClass: "mg",
    utcOffset: "",
    latitude: -20,
    longitude: 47,
  },
  MH: {
    phonePrefix: "+692",
    fullName: "Marshall Islands",
    currencySign: "",
    currency: "USD",
    countryCode: "MH",
    flagClass: "mh",
    utcOffset: "",
    latitude: 9,
    longitude: 168,
  },
  MK: {
    phonePrefix: "+389",
    fullName: "Macedonia",
    currencySign: "",
    currency: "MKD",
    countryCode: "MK",
    flagClass: "mk",
    utcOffset: "",
    latitude: 41.83333333,
    longitude: 22,
  },
  ML: {
    phonePrefix: "+223",
    fullName: "Mali",
    currencySign: "",
    currency: "XOF",
    countryCode: "ML",
    flagClass: "ml",
    utcOffset: "",
    latitude: 17,
    longitude: -4,
  },
  MM: {
    phonePrefix: "+95",
    fullName: "Myanmar",
    currencySign: "",
    currency: "MMK",
    countryCode: "MM",
    flagClass: "mm",
    utcOffset: "",
    latitude: 22,
    longitude: 98,
  },
  MN: {
    phonePrefix: "+976",
    fullName: "Mongolia",
    currencySign: "₮",
    currency: "MNT",
    countryCode: "MN",
    flagClass: "mn",
    utcOffset: "+08:00",
    latitude: "0",
    longitude: "0",
  },
  MO: {
    phonePrefix: "+853",
    fullName: "Macau",
    currencySign: "",
    currency: "MOP",
    countryCode: "MO",
    flagClass: "mo",
    utcOffset: "",
    latitude: 22.16666666,
    longitude: 113.55,
  },
  MP: {
    phonePrefix: "+1670",
    fullName: "Northern Mariana Islands",
    currencySign: "",
    currency: "USD",
    countryCode: "MP",
    flagClass: "mp",
    utcOffset: "",
    latitude: 15.2,
    longitude: 145.75,
  },
  MQ: {
    phonePrefix: "+596",
    fullName: "Martinique",
    currencySign: "",
    currency: "EUR",
    countryCode: "MQ",
    flagClass: "mq",
    utcOffset: "",
    latitude: 14.666667,
    longitude: -61,
  },
  MR: {
    phonePrefix: "+222",
    fullName: "Mauritania",
    currencySign: "",
    currency: "MRO",
    countryCode: "MR",
    flagClass: "mr",
    utcOffset: "",
    latitude: 20,
    longitude: -12,
  },
  MS: {
    phonePrefix: "+1664",
    fullName: "Montserrat",
    currencySign: "",
    currency: "XCD",
    countryCode: "MS",
    flagClass: "ms",
    utcOffset: "",
    latitude: 16.75,
    longitude: -62.2,
  },
  MT: {
    phonePrefix: "+356",
    fullName: "Malta",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "MT",
    flagClass: "mt",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  MU: {
    phonePrefix: "+230",
    fullName: "Mauritius",
    currencySign: "",
    currency: "MUR",
    countryCode: "MU",
    flagClass: "mu",
    utcOffset: "",
    latitude: -20.28333333,
    longitude: 57.55,
  },
  MV: {
    phonePrefix: "+960",
    fullName: "Maldives",
    currencySign: "",
    currency: "MVR",
    countryCode: "MV",
    flagClass: "mv",
    utcOffset: "",
    latitude: 3.25,
    longitude: 73,
  },
  MW: {
    phonePrefix: "+265",
    fullName: "Malawi",
    currencySign: "",
    currency: "MWK",
    countryCode: "MW",
    flagClass: "mw",
    utcOffset: "",
    latitude: -13.5,
    longitude: 34,
  },
  MX: {
    phonePrefix: "+52",
    fullName: "Mexico",
    currencySign: "",
    currency: "MXN",
    countryCode: "MX",
    flagClass: "mx",
    utcOffset: "",
    latitude: 23,
    longitude: -102,
  },
  MY: {
    phonePrefix: "+60",
    fullName: "Malaysia",
    currencySign: "‎RM",
    currency: "MYR",
    countryCode: "MY",
    flagClass: "my",
    utcOffset: "+07:00",
    latitude: "0",
    longitude: "0",
  },
  MZ: {
    phonePrefix: "+258",
    fullName: "Mozambique",
    currencySign: "",
    currency: "MZN",
    countryCode: "MZ",
    flagClass: "mz",
    utcOffset: "",
    latitude: -18.25,
    longitude: 35,
  },
  NA: {
    phonePrefix: "+264",
    fullName: "Namibia",
    currencySign: "",
    currency: "NAD",
    countryCode: "NA",
    flagClass: "na",
    utcOffset: "",
    latitude: -22,
    longitude: 17,
  },
  NC: {
    phonePrefix: "+687",
    fullName: "New Caledonia",
    currencySign: "",
    currency: "XPF",
    countryCode: "NC",
    flagClass: "nc",
    utcOffset: "",
    latitude: -21.5,
    longitude: 165.5,
  },
  NE: {
    phonePrefix: "+227",
    fullName: "Niger",
    currencySign: "",
    currency: "XOF",
    countryCode: "NE",
    flagClass: "ne",
    utcOffset: "",
    latitude: 16,
    longitude: 8,
  },
  NF: {
    phonePrefix: "+672",
    fullName: "Norfolk Island",
    currencySign: "",
    currency: "AUD",
    countryCode: "NF",
    flagClass: "nf",
    utcOffset: "",
    latitude: -29.03333333,
    longitude: 167.95,
  },
  NG: {
    phonePrefix: "+234",
    fullName: "Nigeria",
    currencySign: "₦",
    currency: "NGN",
    countryCode: "NG",
    flagClass: "ng",
    utcOffset: "+01:00",
    latitude: "9.081999",
    longitude: "8.675277",
  },
  NI: {
    phonePrefix: "+505",
    fullName: "Nicaragua",
    currencySign: "",
    currency: "NIO",
    countryCode: "NI",
    flagClass: "ni",
    utcOffset: "",
    latitude: 13,
    longitude: -85,
  },
  NL: {
    phonePrefix: "+31",
    fullName: "Netherlands",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "NL",
    flagClass: "nl",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  NO: {
    phonePrefix: "+47",
    fullName: "Norway",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "NO",
    flagClass: "no",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  NP: {
    phonePrefix: "+977",
    fullName: "Nepal",
    currencySign: "‎रू",
    currency: "NPR",
    countryCode: "NP",
    flagClass: "np",
    utcOffset: "+05:45",
    latitude: "0",
    longitude: "0",
  },
  NR: {
    phonePrefix: "+674",
    fullName: "Nauru",
    currencySign: "",
    currency: "AUD",
    countryCode: "NR",
    flagClass: "nr",
    utcOffset: "",
    latitude: -0.53333333,
    longitude: 166.91666666,
  },
  NU: {
    phonePrefix: "+683",
    fullName: "Niue",
    currencySign: "",
    currency: "NZD",
    countryCode: "NU",
    flagClass: "nu",
    utcOffset: "",
    latitude: -19.03333333,
    longitude: -169.86666666,
  },
  NZ: {
    phonePrefix: "+64",
    fullName: "New Zealand",
    currencySign: "$",
    currency: "NZD",
    countryCode: "NZ",
    flagClass: "nz",
    utcOffset: "+12:00",
    latitude: "0",
    longitude: "0",
  },
  OM: {
    phonePrefix: "+968",
    fullName: "Oman",
    currencySign: "",
    currency: "OMR",
    countryCode: "OM",
    flagClass: "om",
    utcOffset: "",
    latitude: 21,
    longitude: 57,
  },
  PA: {
    phonePrefix: "+507",
    fullName: "Panama",
    currencySign: "",
    currency: "PAB",
    countryCode: "PA",
    flagClass: "pa",
    utcOffset: "",
    latitude: 9,
    longitude: -80,
  },
  PE: {
    phonePrefix: "+51",
    fullName: "Peru",
    currencySign: "‎$",
    currency: "PEN",
    countryCode: "PE",
    flagClass: "pe",
    utcOffset: "-05:00",
    latitude: "0",
    longitude: "0",
  },
  PF: {
    phonePrefix: "+689",
    fullName: "French Polynesia",
    currencySign: "",
    currency: "XPF",
    countryCode: "PF",
    flagClass: "pf",
    utcOffset: "",
    latitude: -15,
    longitude: -140,
  },
  PG: {
    phonePrefix: "+675",
    fullName: "Papua New Guinea",
    currencySign: "",
    currency: "PGK",
    countryCode: "PG",
    flagClass: "pg",
    utcOffset: "",
    latitude: -6,
    longitude: 147,
  },
  PH: {
    phonePrefix: "+63",
    fullName: "Philippines",
    currencySign: "₱",
    currency: "PHP",
    countryCode: "PH",
    flagClass: "ph",
    utcOffset: "+08:00",
    latitude: "12.879721",
    longitude: "121.774017",
  },
  PK: {
    phonePrefix: "+92",
    fullName: "Pakistan",
    currencySign: "‎₨",
    currency: "PKR",
    countryCode: "PK",
    flagClass: "pk",
    utcOffset: "+05:00",
    latitude: "0",
    longitude: "0",
  },
  PL: {
    phonePrefix: "+48",
    fullName: "Poland",
    currencySign: "‎€",
    currency: "PLN",
    countryCode: "PL",
    flagClass: "pl",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  PM: {
    phonePrefix: "+508",
    fullName: "Saint Pierre and Miquelon",
    currencySign: "",
    currency: "EUR",
    countryCode: "PM",
    flagClass: "pm",
    utcOffset: "",
    latitude: 46.83333333,
    longitude: -56.33333333,
  },
  PN: {
    phonePrefix: "+64",
    fullName: "Pitcairn Islands",
    currencySign: "",
    currency: "NZD",
    countryCode: "PN",
    flagClass: "pn",
    utcOffset: "",
    latitude: -25.06666666,
    longitude: -130.1,
  },
  PR: {
    phonePrefix: "+1787",
    fullName: "Puerto Rico",
    currencySign: "",
    currency: "USD",
    countryCode: "PR",
    flagClass: "pr",
    utcOffset: "",
    latitude: 18.25,
    longitude: -66.5,
  },
  PS: {
    phonePrefix: "+970",
    fullName: "Palestine",
    currencySign: "",
    currency: "ILS",
    countryCode: "PS",
    flagClass: "ps",
    utcOffset: "",
    latitude: 31.9,
    longitude: 35.2,
  },
  PT: {
    phonePrefix: "+351",
    fullName: "Portugal",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "PT",
    flagClass: "pt",
    utcOffset: "-01:00",
    latitude: 39.5,
    longitude: -8,
  },
  PW: {
    phonePrefix: "+680",
    fullName: "Palau",
    currencySign: "",
    currency: "USD",
    countryCode: "PW",
    flagClass: "pw",
    utcOffset: "",
    latitude: 7.5,
    longitude: 134.5,
  },
  PY: {
    phonePrefix: "+595",
    fullName: "Paraguay",
    currencySign: "",
    currency: "PYG",
    countryCode: "PY",
    flagClass: "py",
    utcOffset: "",
    latitude: -23,
    longitude: -58,
  },
  QA: {
    phonePrefix: "+974",
    fullName: "Qatar",
    currencySign: "",
    currency: "QAR",
    countryCode: "QA",
    flagClass: "qa",
    utcOffset: "",
    latitude: 25.5,
    longitude: 51.25,
  },
  RE: {
    phonePrefix: "+262",
    fullName: "Réunion",
    currencySign: "",
    currency: "EUR",
    countryCode: "RE",
    flagClass: "re",
    utcOffset: "",
    latitude: -21.15,
    longitude: 55.5,
  },
  RO: {
    phonePrefix: "+40",
    fullName: "Romania",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "RO",
    flagClass: "ro",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  RS: {
    phonePrefix: "+381",
    fullName: "Serbia",
    currencySign: "",
    currency: "RSD",
    countryCode: "RS",
    flagClass: "rs",
    utcOffset: "",
    latitude: 44,
    longitude: 21,
  },
  RU: {
    phonePrefix: "+7",
    fullName: "Russian Federation",
    currencySign: "₽",
    currency: "RUB",
    countryCode: "RU",
    flagClass: "ru",
    utcOffset: "+02:00",
    latitude: "0",
    longitude: "0",
  },
  RW: {
    phonePrefix: "+250",
    fullName: "Rwanda",
    currencySign: "",
    currency: "RWF",
    countryCode: "RW",
    flagClass: "rw",
    utcOffset: "",
    latitude: -2,
    longitude: 30,
  },
  SA: {
    phonePrefix: "+966",
    fullName: "Saudi Arabia",
    currencySign: "",
    currency: "SAR",
    countryCode: "SA",
    flagClass: "sa",
    utcOffset: "",
    latitude: 25,
    longitude: 45,
  },
  SB: {
    phonePrefix: "+677",
    fullName: "Solomon Islands",
    currencySign: "",
    currency: "SBD",
    countryCode: "SB",
    flagClass: "sb",
    utcOffset: "",
    latitude: -8,
    longitude: 159,
  },
  SC: {
    phonePrefix: "+248",
    fullName: "Seychelles",
    currencySign: "",
    currency: "SCR",
    countryCode: "SC",
    flagClass: "sc",
    utcOffset: "",
    latitude: -4.58333333,
    longitude: 55.66666666,
  },
  SD: {
    phonePrefix: "+249",
    fullName: "Sudan",
    currencySign: "",
    currency: "SDG",
    countryCode: "SD",
    flagClass: "sd",
    utcOffset: "",
    latitude: 15,
    longitude: 30,
  },
  SE: {
    phonePrefix: "+46",
    fullName: "Sweden",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "SE",
    flagClass: "se",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  SG: {
    phonePrefix: "+65",
    fullName: "Singapore",
    currencySign: "‎$",
    currency: "SGD",
    countryCode: "SG",
    flagClass: "sg",
    utcOffset: "+08:00",
    latitude: "0",
    longitude: "0",
  },
  SH: {
    phonePrefix: "+290",
    fullName: "Saint Helena, Ascension and Tristan da Cunha",
    currencySign: "",
    currency: "SHP",
    countryCode: "SH",
    flagClass: "sh",
    utcOffset: "",
    latitude: -15.95,
    longitude: -5.72,
  },
  SI: {
    phonePrefix: "+386",
    fullName: "Slovenia",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "SI",
    flagClass: "si",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  SJ: {
    phonePrefix: "+4779",
    fullName: "Svalbard and Jan Mayen",
    currencySign: "",
    currency: "NOK",
    countryCode: "SJ",
    flagClass: "sj",
    utcOffset: "",
    latitude: 78,
    longitude: 20,
  },
  SK: {
    phonePrefix: "+421",
    fullName: "Slovakia",
    currencySign: "‎€",
    currency: "EUR",
    countryCode: "SK",
    flagClass: "sk",
    utcOffset: "+01:00",
    latitude: "0",
    longitude: "0",
  },
  SL: {
    phonePrefix: "+232",
    fullName: "Sierra Leone",
    currencySign: "",
    currency: "SLE",
    countryCode: "SL",
    flagClass: "sl",
    utcOffset: "",
    latitude: 8.5,
    longitude: -11.5,
  },
  SM: {
    phonePrefix: "+378",
    fullName: "San Marino",
    currencySign: "",
    currency: "EUR",
    countryCode: "SM",
    flagClass: "sm",
    utcOffset: "",
    latitude: 43.76666666,
    longitude: 12.41666666,
  },
  SN: {
    phonePrefix: "+221",
    fullName: "Senegal",
    currencySign: "",
    currency: "XOF",
    countryCode: "SN",
    flagClass: "sn",
    utcOffset: "",
    latitude: 14,
    longitude: -14,
  },
  SO: {
    phonePrefix: "+252",
    fullName: "Somalia",
    currencySign: "",
    currency: "SOS",
    countryCode: "SO",
    flagClass: "so",
    utcOffset: "",
    latitude: 10,
    longitude: 49,
  },
  SR: {
    phonePrefix: "+597",
    fullName: "Suriname",
    currencySign: "",
    currency: "SRD",
    countryCode: "SR",
    flagClass: "sr",
    utcOffset: "",
    latitude: 4,
    longitude: -56,
  },
  SS: {
    phonePrefix: "+211",
    fullName: "South Sudan",
    currencySign: "",
    currency: "SSP",
    countryCode: "SS",
    flagClass: "ss",
    utcOffset: "",
    latitude: 7,
    longitude: 30,
  },
  ST: {
    phonePrefix: "+239",
    fullName: "São Tomé and Príncipe",
    currencySign: "",
    currency: "STD",
    countryCode: "ST",
    flagClass: "st",
    utcOffset: "",
    latitude: 1,
    longitude: 7,
  },
  SV: {
    phonePrefix: "+503",
    fullName: "El Salvador",
    currencySign: "",
    currency: "SVC",
    countryCode: "SV",
    flagClass: "sv",
    utcOffset: "",
    latitude: 13.83333333,
    longitude: -88.91666666,
  },
  SX: {
    phonePrefix: "+1721",
    fullName: "Sint Maarten",
    currencySign: "",
    currency: "ANG",
    countryCode: "SX",
    flagClass: "sx",
    utcOffset: "",
    latitude: 18.033333,
    longitude: -63.05,
  },
  SY: {
    phonePrefix: "+963",
    fullName: "Syria",
    currencySign: "",
    currency: "SYP",
    countryCode: "SY",
    flagClass: "sy",
    utcOffset: "",
    latitude: 35,
    longitude: 38,
  },
  SZ: {
    phonePrefix: "+268",
    fullName: "Eswatini",
    currencySign: "",
    currency: "SZL",
    countryCode: "SZ",
    flagClass: "sz",
    utcOffset: "",
    latitude: -26.5,
    longitude: 31.5,
  },
  TC: {
    phonePrefix: "+1649",
    fullName: "Turks and Caicos Islands",
    currencySign: "",
    currency: "USD",
    countryCode: "TC",
    flagClass: "tc",
    utcOffset: "",
    latitude: 21.75,
    longitude: -71.58333333,
  },
  TD: {
    phonePrefix: "+235",
    fullName: "Chad",
    currencySign: "",
    currency: "XAF",
    countryCode: "TD",
    flagClass: "td",
    utcOffset: "",
    latitude: 15,
    longitude: 19,
  },
  // TF: {
  //   phonePrefix: '+undefined',
  //   fullName: 'French Southern and Antarctic Lands',
  //   currencySign: '',
  //   currency: 'EUR',
  //   countryCode: 'TF',
  //   flagClass: 'tf',
  //   utcOffset: '',
  //   latitude: -49.25,
  //   longitude: 69.167,
  // },
  TG: {
    phonePrefix: "+228",
    fullName: "Togo",
    currencySign: "",
    currency: "XOF",
    countryCode: "TG",
    flagClass: "tg",
    utcOffset: "",
    latitude: 8,
    longitude: 1.16666666,
  },
  TH: {
    phonePrefix: "+66",
    fullName: "Thailand",
    currencySign: "‎฿",
    currency: "THB",
    countryCode: "TH",
    flagClass: "th",
    utcOffset: "+07:00",
    latitude: "0",
    longitude: "0",
  },
  TJ: {
    phonePrefix: "+992",
    fullName: "Tajikistan",
    currencySign: "sm",
    currency: "TJS",
    countryCode: "TJ",
    flagClass: "tj",
    utcOffset: "+05:00",
    latitude: "0",
    longitude: "0",
  },
  TK: {
    phonePrefix: "+690",
    fullName: "Tokelau",
    currencySign: "",
    currency: "NZD",
    countryCode: "TK",
    flagClass: "tk",
    utcOffset: "",
    latitude: -9,
    longitude: -172,
  },
  TL: {
    phonePrefix: "+670",
    fullName: "Timor-Leste",
    currencySign: "",
    currency: "USD",
    countryCode: "TL",
    flagClass: "tl",
    utcOffset: "",
    latitude: -8.83333333,
    longitude: 125.91666666,
  },
  TM: {
    phonePrefix: "+993",
    fullName: "Turkmenistan",
    currencySign: "",
    currency: "TMT",
    countryCode: "TM",
    flagClass: "tm",
    utcOffset: "",
    latitude: 40,
    longitude: 60,
  },
  TN: {
    phonePrefix: "+216",
    fullName: "Tunisia",
    currencySign: "",
    currency: "TND",
    countryCode: "TN",
    flagClass: "tn",
    utcOffset: "",
    latitude: 34,
    longitude: 9,
  },
  TO: {
    phonePrefix: "+676",
    fullName: "Tonga",
    currencySign: "",
    currency: "TOP",
    countryCode: "TO",
    flagClass: "to",
    utcOffset: "",
    latitude: -20,
    longitude: -175,
  },
  TR: {
    phonePrefix: "+90",
    fullName: "Turkey",
    currencySign: "‎₺",
    currency: "TRY",
    countryCode: "TR",
    flagClass: "tr",
    utcOffset: "+03:00",
    latitude: "38.963745",
    longitude: "35.243322",
  },
  TT: {
    phonePrefix: "+1868",
    fullName: "Trinidad and Tobago",
    currencySign: "",
    currency: "TTD",
    countryCode: "TT",
    flagClass: "tt",
    utcOffset: "",
    latitude: 11,
    longitude: -61,
  },
  TV: {
    phonePrefix: "+688",
    fullName: "Tuvalu",
    currencySign: "",
    currency: "AUD",
    countryCode: "TV",
    flagClass: "tv",
    utcOffset: "",
    latitude: -8,
    longitude: 178,
  },
  TW: {
    phonePrefix: "+886",
    fullName: "Taiwan, Province of China",
    currencySign: "NT$",
    currency: "TWD",
    countryCode: "TW",
    flagClass: "tw",
    utcOffset: "+08:00",
    latitude: "0",
    longitude: "0",
  },
  TZ: {
    phonePrefix: "+255",
    fullName: "Tanzania",
    currencySign: "",
    currency: "TZS",
    countryCode: "TZ",
    flagClass: "tz",
    utcOffset: "",
    latitude: -6,
    longitude: 35,
  },
  UA: {
    phonePrefix: "+380",
    fullName: "Ukraine",
    currencySign: "‎$",
    currency: "USD",
    countryCode: "UA",
    flagClass: "ua",
    utcOffset: "+03:00",
    latitude: "0",
    longitude: "0",
  },
  UG: {
    phonePrefix: "+256",
    fullName: "Uganda",
    currencySign: "",
    currency: "UGX",
    countryCode: "UG",
    flagClass: "ug",
    utcOffset: "",
    latitude: 1,
    longitude: 32,
  },
  UM: {
    phonePrefix: "+1",
    fullName: "United States Minor Outlying Islands",
    currencySign: "",
    currency: "USD",
    countryCode: "UM",
    flagClass: "um",
    utcOffset: "",
    latitude: 19.3,
    longitude: 166.633333,
  },
  UY: {
    phonePrefix: "+598",
    fullName: "Uruguay",
    currencySign: "",
    currency: "UYU",
    countryCode: "UY",
    flagClass: "uy",
    utcOffset: "",
    latitude: -33,
    longitude: -56,
  },
  UZ: {
    phonePrefix: "+998",
    fullName: "Uzbekistan",
    currencySign: "‎$",
    currency: "UZS",
    countryCode: "UZ",
    flagClass: "uz",
    utcOffset: "+05:00",
    latitude: "0",
    longitude: "0",
  },
  VA: {
    phonePrefix: "+3906698",
    fullName: "Vatican City",
    currencySign: "",
    currency: "EUR",
    countryCode: "VA",
    flagClass: "va",
    utcOffset: "",
    latitude: 41.9,
    longitude: 12.45,
  },
  VC: {
    phonePrefix: "+1784",
    fullName: "Saint Vincent and the Grenadines",
    currencySign: "",
    currency: "XCD",
    countryCode: "VC",
    flagClass: "vc",
    utcOffset: "",
    latitude: 13.25,
    longitude: -61.2,
  },
  VE: {
    phonePrefix: "+58",
    fullName: "Venezuela",
    currencySign: "",
    currency: "VEF",
    countryCode: "VE",
    flagClass: "ve",
    utcOffset: "",
    latitude: 8,
    longitude: -66,
  },
  VG: {
    phonePrefix: "+1284",
    fullName: "British Virgin Islands",
    currencySign: "",
    currency: "USD",
    countryCode: "VG",
    flagClass: "vg",
    utcOffset: "",
    latitude: 18.431383,
    longitude: -64.62305,
  },
  VI: {
    phonePrefix: "+1340",
    fullName: "United States Virgin Islands",
    currencySign: "",
    currency: "USD",
    countryCode: "VI",
    flagClass: "vi",
    utcOffset: "",
    latitude: 18.35,
    longitude: -64.933333,
  },
  VN: {
    phonePrefix: "+84",
    fullName: "Vietnam",
    currencySign: "‎₫",
    currency: "VND",
    countryCode: "VN",
    flagClass: "vn",
    utcOffset: "+07:00",
    latitude: "0",
    longitude: "0",
  },
  VU: {
    phonePrefix: "+678",
    fullName: "Vanuatu",
    currencySign: "",
    currency: "VUV",
    countryCode: "VU",
    flagClass: "vu",
    utcOffset: "",
    latitude: -16,
    longitude: 167,
  },
  WF: {
    phonePrefix: "+681",
    fullName: "Wallis and Futuna",
    currencySign: "",
    currency: "XPF",
    countryCode: "WF",
    flagClass: "wf",
    utcOffset: "",
    latitude: -13.3,
    longitude: -176.2,
  },
  WS: {
    phonePrefix: "+685",
    fullName: "Samoa",
    currencySign: "",
    currency: "WST",
    countryCode: "WS",
    flagClass: "ws",
    utcOffset: "",
    latitude: -13.58333333,
    longitude: -172.33333333,
  },
  XK: {
    phonePrefix: "+383",
    fullName: "Kosovo",
    currencySign: "",
    currency: "EUR",
    countryCode: "XK",
    flagClass: "xk",
    utcOffset: "",
    latitude: 42.666667,
    longitude: 21.166667,
  },
  YE: {
    phonePrefix: "+967",
    fullName: "Yemen",
    currencySign: "",
    currency: "YER",
    countryCode: "YE",
    flagClass: "ye",
    utcOffset: "",
    latitude: 15,
    longitude: 48,
  },
  YT: {
    phonePrefix: "+262",
    fullName: "Mayotte",
    currencySign: "",
    currency: "EUR",
    countryCode: "YT",
    flagClass: "yt",
    utcOffset: "",
    latitude: -12.83333333,
    longitude: 45.16666666,
  },
  ZA: {
    phonePrefix: "+27",
    fullName: "South Africa",
    currencySign: "‎R",
    currency: "ZAR",
    countryCode: "ZA",
    flagClass: "za",
    utcOffset: "+02:00",
    latitude: "-30.559482",
    longitude: "22.937506",
  },
  ZM: {
    phonePrefix: "+260",
    fullName: "Zambia",
    currencySign: "",
    currency: "ZMW",
    countryCode: "ZM",
    flagClass: "zm",
    utcOffset: "",
    latitude: -15,
    longitude: 30,
  },
  ZW: {
    phonePrefix: "+263",
    fullName: "Zimbabwe",
    currencySign: "",
    currency: "ZWL",
    countryCode: "ZW",
    flagClass: "zw",
    utcOffset: "",
    latitude: -20,
    longitude: 30,
  },
};
